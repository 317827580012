import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import FieldsModal from '@/components/FieldsModal';
import Popover from '@/components/Popover';
import { paramNames } from '@/constants';
import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard } from '@/hooks';
import FilterTag from './FilterTag';

import getFilterParamNames from '../helpers/getFilterParamNames';
import { getFiltersApplied } from './helpers';
import styles from './FilterTag.module.scss';

const FilterTagsContainer = ({
  params, onParamsChange, onParamDelete, onReset,
}) => {
  const dashboard = useActiveDashboard();
  const [defaultOpenParam, setDefaultOpenParam] = useState(null);

  const filterParamNames = useMemo(() => getFilterParamNames(params[paramNames.filterType]), [params]);
  const filtersApplied = useMemo(() => getFiltersApplied(params, filterParamNames), [filterParamNames, params]);

  const handleTagClick = (paramName, callback) => (e) => {
    setDefaultOpenParam(paramName);
    callback(e);
  };

  const handleReset = (close) => {
    onReset();
    close();
  };

  return (
    <div className={styles.tagsContainer}>
      <Popover
        renderContent={({ close }) => (
          <FieldsModal
            title="Filters"
            params={params}
            paramNames={filterParamNames}
            defaultOpenParam={defaultOpenParam}
            onChange={onParamsChange}
            onReset={() => handleReset(close)}
          />
        )}
        renderCustomTrigger={({ open }) => (
          <DashboardPermissionedControl
            dashboardId={dashboard.id}
            requiredPermissions={[USER_ROLES.EDITOR]}
          >
            <div className={styles.tagsWrapper}>
              {Object.entries(filtersApplied).map(([paramName, content]) => (
                <DashboardPermissionedControl
                  key={`permissioned_wrapper_${dashboard.id}_${paramName}`}
                  dashboardId={dashboard.id}
                  requiredPermissions={[USER_ROLES.EDITOR]}
                >
                  <FilterTag
                    key={`${dashboard.id}_${paramName}`}
                    paramName={paramName}
                    content={content}
                    onDelete={onParamDelete}
                    onClick={handleTagClick(paramName, open)}
                  />
                </DashboardPermissionedControl>
              ))}
              <Button
                onClick={open}
                variant="contained"
                size="small"
                shape="rounded"
                color="accent"
                disableElevation
              >
                <TuneIcon fontSize="small" />
              </Button>
            </div>
          </DashboardPermissionedControl>
        )}
      />
    </div>
  );
};

export default FilterTagsContainer;
