import LinearProgress from '@mui/material/LinearProgress';
import { useRequest } from '@/hooks';
import flatten from 'lodash.flatten';
import React, { useEffect } from 'react';
import { fetchGpt, fetchInsightsEntities, fetchInsightsTags } from '@/utils';
import styles from './CreativesModal.module.scss';
import useOutput from './useOutput';
import CreativesModalOutput from './CreativesModalOutput';
import CreativesModalResult from './CreativesModalResult';

const mapEntitiesByName = (item) => item.name;

const fetchEntities = ({ baseParams, entitiesRequests, wrappedFetchInsightsEntities }) => {
  return Promise.all(
    entitiesRequests.map((entitiesRequest) => wrappedFetchInsightsEntities({ baseParams, ...entitiesRequest })
      .then((result) => result?.results?.entities || [])),
  ).then(flatten);
};

const fetchTags = ({ baseParams, tagsRequests, wrappedFetchInsightsTags }) => {
  return Promise.all(
    tagsRequests.map((tagsRequest) => wrappedFetchInsightsTags({ baseParams, ...tagsRequest })
      .then((result) => result?.results?.tags || []).then(
        (tags) => tags.map((tag) => ({
          ...tag, id: tag.tag_id, tagId: tag.tag_id, category: tagsRequest.category,
        })),
      )),
  ).then(flatten);
};

const CreativeModalStepTwo = ({ baseParams, template, userData }) => {
  const output = useOutput();
  const { wrapFn } = output;
  const wrappedFetchInsightsEntities = wrapFn(fetchInsightsEntities, 'Fetching insights entities');
  const wrappedFetchInsightsTags = wrapFn(fetchInsightsTags, 'Fetching insights tags');
  const wrappedFetchGpt = wrapFn(fetchGpt, 'Fetching llm request');

  const entitiesRequest = useRequest(fetchEntities);
  const tagsRequest = useRequest(fetchTags);

  const runnerRequest = useRequest(async () => {
    const { insights } = template;

    const [entities, tags] = await Promise.all([
      entitiesRequest.call({ baseParams, entitiesRequests: insights?.entities || [], wrappedFetchInsightsEntities }),
      tagsRequest.call({ baseParams, tagsRequests: insights?.tags || [], wrappedFetchInsightsTags }),
    ]);

    const insightsNames = [...(entities || []), ...(tags || [])].map(mapEntitiesByName);

    const result = await template.runner({
      baseParams,
      entities,
      fetchGpt: wrappedFetchGpt,
      insightsNames,
      tags,
      userData,
      wrapFn,
    });

    return result;
  });

  useEffect(() => {
    runnerRequest.call();
  }, []);

  return (
    <>
      <div className={styles.contentContainer}>
        <div className={styles.result}>
          <CreativesModalResult
            baseParams={baseParams}
            entitiesRequest={entitiesRequest}
            runnerRequest={runnerRequest}
            tagsRequest={tagsRequest}
            template={template}
          />
          {runnerRequest.error ? (
            <div>{runnerRequest.error?.toString ? runnerRequest.error.toString() : JSON.stringify(runnerRequest.error)}</div>
          ) : null}
        </div>
      </div>
      {runnerRequest.isLoading ? <LinearProgress /> : null}
      <CreativesModalOutput output={output} />
    </>
  );
};

export default CreativeModalStepTwo;
