import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { filterTypes, paramNames } from '@/constants';
import FetchingOverlay from '@/components/Overlay/FetchingOverlay';
import Overlay from '@/components/Overlay';
import PanelBodyTable from './PanelBodyTable';
import PanelBodyHeatmap from './PanelBodyHeatmap';
import PanelBodyExplore from './PanelBodyExplore';
import PanelBodyLocationExplorer from './PanelBodyLocationExplorer';
import PanelBodyError from './PanelBodyError';
import { noInsightPanels } from './helpers/constants';
import { getHumanizedQueryError } from '../FilterTags/helpers';
import styles from './DashboardPanel.module.scss';

const ErrorOverlay = ({ error }) => (
  <Overlay>
    <PanelBodyError error={error} />
  </Overlay>
);

const PanelBodyWrapper = ({
  serializedInsightsParams,
  panelError,
  panel,
  ...props
}) => {
  const { params } = panel;
  const filterType = params[paramNames.filterType];
  const error = getHumanizedQueryError(panelError);
  const hasError = Boolean(error);
  const hasData = Boolean(props.panelData.length);

  const panelBodyId = useMemo(
    () => `dashboardPanelBody-${props.panelId}`,
    [props.panelId],
  );

  useEffect(() => {
    if (!props.isFetching) {
      const panelBody = document.getElementById(panelBodyId);
      panelBody.scrollTop = 0;
    }
  }, [props.isFetching, panelBodyId, serializedInsightsParams]);

  const isInsightPanel = !noInsightPanels.includes(filterType);

  return (
    <div
      className={clsx({
        [styles.panelBodyContainer]: true,
        [styles.explorePanel]: !isInsightPanel,
      })}
    >
      <div
        id={panelBodyId}
        className={clsx({
          [styles.dashboardPanelBody]: isInsightPanel,
          [styles.explorePanel]: !isInsightPanel,
        })}
      >
        <PanelBody filterType={filterType} panel={panel} {...props} />
        {(hasError || (!hasData && isInsightPanel)) && (
          <ErrorOverlay error={error} />
        )}
        {props.isFetching && <FetchingOverlay />}
      </div>
    </div>
  );
};

const PanelBody = ({
  panelId,
  panelParams,
  panelSettings,
  panel,
  panelData,
  filterType,
  baseParams,
  updateHasMoreExplorePages,
  explorePagination,
  isFetching,
}) => {
  const { settings } = panel;
  const heatmapControls = settings?.heatmapControls;
  const tableControls = settings?.tableControls;
  const exploreControls = panelSettings?.exploreControls;

  if (filterType === filterTypes.heatmap) {
    return (
      <PanelBodyHeatmap
        data={panelData}
        panelId={panelId}
        heatmapControls={heatmapControls}
        isFetching={isFetching}
      />
    );
  }

  if (filterType === filterTypes.explore) {
    // TODO: Check this
    const exploreParams = baseParams;
    delete exploreParams[paramNames.filterType];

    return (
      <PanelBodyExplore
        data={panelData}
        panel={panel}
        panelId={panelId}
        baseParams={exploreParams}
        pagination={explorePagination}
        updateHasMoreExplorePages={updateHasMoreExplorePages}
        panelSettings={settings}
      />
    );
  }

  if (filterType === filterTypes.locationExplorer) {
    // TODO: Check this
    const exploreParams = baseParams;
    delete exploreParams[paramNames.filterType];

    return (
      <PanelBodyLocationExplorer
        panelId={panelId}
        panelParams={panelParams}
        panelSettings={settings}
        panel={panel}
        baseParams={exploreParams}
        explorePagination={explorePagination}
        exploreControls={exploreControls}
        updateHasMoreExplorePages={updateHasMoreExplorePages}
      />
    );
  }

  return (
    <PanelBodyTable
      data={panelData}
      panelId={panelId}
      filterType={filterType}
      tableControls={tableControls}
      isFetching={isFetching}
    />
  );
};

export default PanelBodyWrapper;
