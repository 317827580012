import { Grid } from 'react-feather';
import TabButton from '@/components/TabButton';

import styles from './DashboardTabs.module.scss';

const DashboardTabsHomeButton = () => (
  <TabButton
    href="#/dashboards"
    sx={{
      height: '100%',
      width: 38,
      minWidth: 38,
    }}
  >
    <Grid className={styles.buttonIcon} size={14} />
  </TabButton>
);

export default DashboardTabsHomeButton;
