/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: I did not want to add tabIndex, empty keydown listener, or role="button" to the divs
//       because it could have messed with the current behaviuor of the category popover, need to revisit later

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { capitalize } from '@mui/material';
import { hexToRgb } from '@mui/system';
import { urnToCategoryData } from '@qloo/categories';
import Popover from '@/components/Popover';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { useActiveDashboard } from '@/hooks';
import CategoriesModal from '@/components/CategoriesModal';
import { USER_ROLES } from '@/constants/roles';
import styles from './CategoryPopover.module.scss';

const Subcategories = ({ subcategories, additionalClass = '' }) => {
  return subcategories.map((subcategory, index) => {
    const splicedValue = subcategory.replace(/_/g, ' ').split(':');
    const finalText = capitalize(splicedValue[splicedValue.length - 1]);

    return (
      <React.Fragment key={`${subcategory}`}>
        <span className={`${additionalClass} truncate`}>{finalText}</span>
        {index < subcategories.length - 1 ? ', ' : ''}
      </React.Fragment>
    );
  });
};

const CategoryPopoverButton = ({
  categoryUrn,
  subCategories = {},
  onClick,
  enforceInlineBehavior,
}) => {
  let categoryData = '';
  if (categoryUrn) {
    if (categoryUrn === 'urn:tag') {
      categoryData = { name: 'Tag' };
    } else {
      categoryData = urnToCategoryData(categoryUrn);
    }
  }

  const typeFilter = (type) => Object.entries(subCategories).filter(([, value]) => value === type).map(([key]) => key);

  // TODO: check why we are using subCategories and if we can add typeFilter here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const valueIncludeFilter = useMemo(() => typeFilter('include'), [subCategories]);
  // TODO: Same as above
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const valueExcludeFilter = useMemo(() => typeFilter('exclude'), [subCategories]);

  return (
    <div
      onClick={onClick}
      className={clsx(
        {
          [styles.buttonLabel]: !enforceInlineBehavior,
          [styles.buttonLabelFromPanelHeader]: enforceInlineBehavior,
        },
      )}
    >
      <span className={styles.categoryText}>
        {categoryData ? categoryData.name : 'Any category'}
      </span>
      {valueIncludeFilter.length > 0 && (
        <>
          <span className={
            clsx(
              {
                [styles.separator]: !enforceInlineBehavior,
                [styles.separatorFromPanelHeader]: enforceInlineBehavior,
              },
            )
          }
          >/
          </span>
          <span className={
            clsx(
              {
                [styles.subcategoryText]: !enforceInlineBehavior,
                [styles.subcategoryTextFromPanelHeader]: enforceInlineBehavior,
              },
            )
          }
          >
            <span
              className={styles.badgeCount}
              style={{
                '--primary-color': categoryData?.colors?.primary ? hexToRgb(
                  categoryData?.colors?.primary,
                )
                  .replace(/rgb|\(|\)/g, '')
                  .trim() : 'var(--category-default-rgb)',
              }}
            >
              {valueIncludeFilter.length}
            </span>
            <Subcategories subcategories={valueIncludeFilter} />
          </span>
        </>
      )}
      {valueExcludeFilter.length > 0 && (
        <>
          <span className={clsx({
            [styles.separator]: !enforceInlineBehavior,
            [styles.separatorFromPanelHeader]: enforceInlineBehavior,
            [styles.minor]: valueIncludeFilter.length > 0,
          })}
          >
            {valueIncludeFilter.length > 0 ? ' but not ' : ' / '}
          </span>
          <span className={
            clsx(
              {
                [styles.subcategoryText]: !enforceInlineBehavior,
                [styles.subcategoryTextFromPanelHeader]: enforceInlineBehavior,
              },
            )
          }
          >
            <span
              className={styles.badgeCount}
              style={{
                '--primary-color': categoryData?.colors?.primary ? hexToRgb(
                  categoryData?.colors?.primary,
                )
                  .replace(/rgb|\(|\)/g, '')
                  .trim() : 'var(--category-default-rgb)',
              }}
            >
              {valueExcludeFilter.length}
            </span>
            <Subcategories subcategories={valueExcludeFilter} additionalClass={styles.excluded} />
          </span>
        </>
      )}
    </div>
  );
};

const CategoryPopover = ({
  category, subCategories, onCategoryChange, onSubcategoriesChange, onReset, showSubCategorySelect, enforceInlineBehavior,
}) => {
  const dashboard = useActiveDashboard();

  return (
    <Popover
      color="accent"
      variant="contained"
      size="small"
      renderContent={({ close }) => (
        <CategoriesModal
          category={category}
          subCategories={subCategories}
          close={close}
          onReset={onReset}
          onCategoryChange={onCategoryChange}
          onSubcategoriesChange={onSubcategoriesChange}
          showSubCategorySelect={showSubCategorySelect}
        />
      )}
      useDynamicPosition
      renderCustomTrigger={({ open }) => (
        <DashboardPermissionedControl
          dashboardId={dashboard.id}
          requiredPermissions={[USER_ROLES.EDITOR]}
        >
          <CategoryPopoverButton
            onClick={open}
            categoryUrn={category}
            subCategories={subCategories}
            enforceInlineBehavior={enforceInlineBehavior}
          />
        </DashboardPermissionedControl>
      )}
    />
  );
};

export default CategoryPopover;
