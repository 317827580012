import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VeltCommentTool,
  VeltCommentsSidebar,
  VeltPresence,
  VeltSidebarButton,
} from '@veltdev/react';
import { USER_ROLES } from '@/constants/roles';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { setPresenceUsers } from '@/store/slices/appSlice';
import keyBy from '@/utils/keyBy';
import { useActiveDashboard } from '@/hooks';
import NewInsightPopover from './NewInsightPopover';
import { useDashboardVeltContext } from '../DashboardVeltProvider';

import styles from './DashboardToolbar.module.scss';

const filterConfig = {
  location: {
    enable: false, // enable/disable location filter
    name: 'Pages', // customize location filter heading
    enableGrouping: true, // to enable/disable grouping based on location filter
    multiSelection: false, // to enable/disable multiple selection
    order: ['locationId1', 'locationId2', 'locationId3'], // pass array of location ids here
  },
  people: {
    enable: true, // enable/disable people filter
    name: 'People', // customize people filter heading
    enableGrouping: false, // to enable/disable grouping based on people filter
    multiSelection: false, // to enable/disable multiple selection
  },
  priority: {
    enable: false, // enable/disable priority filter
    name: 'Priority', // customize priority filter heading
    enableGrouping: false, // to enable/disable grouping based on priority filter
    multiSelection: false, // to enable/disable multiple selection
  },
  category: {
    enable: false, // enable/disable category filter
    name: 'Category', // customize category filter heading
    enableGrouping: false, // to enable/disable grouping based on category filter
    multiSelection: false, // to enable/disable multiple selection
  },
};

const groupConfig = {
  enable: false, // to enable/disable group by option
  name: 'Custom Group By', // customize group by heading
};

const DashboardToolbar = () => {
  const dashboard = useActiveDashboard();
  const { isCommentingToggle, isVeltInitialized } = useDashboardVeltContext();

  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.app);

  const handlePresenceUserChange = (nextUsers) => {
    const userByIds = keyBy(nextUsers, 'userId');
    dispatch(setPresenceUsers(userByIds));
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          <DashboardPermissionedControl
            dashboardId={dashboard.id}
            requiredPermissions={[USER_ROLES.EDITOR]}
            hideIfUnauthorized
          >
            <NewInsightPopover />
          </DashboardPermissionedControl>
        </div>
        <div className={styles.toolbarRight}>
          <div className={styles.toolbarItem}>
            {isVeltInitialized ? (
              <VeltPresence
                flockMode
                onPresenceUserChange={handlePresenceUserChange}
                darkMode={isDarkMode}
              />
            ) : null}
          </div>
          {isVeltInitialized ? (
            <>
              <div className={styles.toolbarItem}>
                <VeltCommentTool onCommentModeChange={isCommentingToggle.set} darkMode={isDarkMode} />
              </div>
              <div className={styles.toolbarItem}>
                <VeltSidebarButton darkMode={isDarkMode} />
              </div>
            </>
          ) : null}
        </div>
      </div>
      {isVeltInitialized ? <VeltCommentsSidebar filterConfig={filterConfig} groupConfig={groupConfig} /> : null}
    </>
  );
};

export default DashboardToolbar;
