import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import Slide from '@mui/material/Slide';
import { alpha, styled } from '@mui/material/styles';
import { useScrolledFromTop } from '@v1/components/Header/AppBar';
import AppBar from '@mui/material/AppBar';
import makeStyles from '@mui/styles/makeStyles';
import ErrorBoundary from '@/components/ErrorBoundary';
import { useActiveDashboard } from '@/hooks';
import { useGetDashboardQuery } from '@/store/apis/dashboardsApi';
import { addAndPersistDashboardTabId } from '@/store/slices/dashboardTabsSlice';

import DashboardHeader from '../DashboardHeader/DashboardHeader';
import DashboardBody from '../DashboardBody/DashboardBody';
import DashboardViewSkeleton from './DashboardViewSkeleton';
import DashboardErrorFallback from '../DashboardErrorFallback/DashboardErrorFallback';

import DashboardActionsProvider from '../DashboardActionsProvider';
import DashboardVeltProvider from '../DashboardVeltProvider';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  headerContainer: {
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.4,
  },
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => {
  const trigger = useScrolledFromTop({ threshold: 180 });

  return {
    position: 'fixed',
    top: 64,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.background.paper, 1),
    boxShadow: 'rgba(0,0,0,0) 0px 2px 10px',
    transition: theme.transitions.create(['width', 'margin', 'box-shadow'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(trigger && {
      backdropFilter: 'blur(6px)',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 2px 10px',
      top: 0,
    }),
  };
});

const DashboardContainerWithBoundary = (props) => (
  <ErrorBoundary FallbackComponent={DashboardErrorFallback}>
    <DashboardContainer {...props} />
  </ErrorBoundary>
);

const DashboardContainer = ({ isLoading }) => {
  const dashboard = useActiveDashboard();
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();

  // Fetch dashboard if not in store
  const {
    isLoading: isDashboardLoading,
    error: dashboardError,
  } = useGetDashboardQuery(
    { id },
    { skip: isLoading || !id || (dashboard && dashboard.baseParams && dashboard.grantedPermissionTagIds) },
  );

  if (isDashboardLoading || isLoading) {
    return <DashboardViewSkeleton />;
  }

  if (dashboardError || !dashboard || !dashboard.baseParams) {
    showBoundary({
      dashboardId: id,
      data: dashboardError?.data,
      status: dashboardError?.status,
    });
    return null;
  }

  return (
    <DashboardVeltProvider dashboard={dashboard}>
      <DashboardActionsProvider dashboard={dashboard}>
        <Dashboard dashboard={dashboard} key={id} />
      </DashboardActionsProvider>
    </DashboardVeltProvider>
  );
};

const Dashboard = ({ dashboard }) => {
  const dispatch = useDispatch();
  const isScrolledFromTop = useScrolledFromTop({ threshold: 180 });
  const classes = useStyles();

  useEffect(() => {
    dispatch(addAndPersistDashboardTabId(dashboard.id));
  }, [dashboard.id]);

  return (
    <div className={classes.container}>
      <Slide appear={false} direction="down" in={isScrolledFromTop}>
        <StyledAppBar>
          <DashboardHeader stickyView dashboard={dashboard} />
        </StyledAppBar>
      </Slide>

      <div className={`${classes.headerContainer} ${!isScrolledFromTop ? classes.visible : classes.hidden}`}>
        <DashboardHeader dashboard={dashboard} />
      </div>
      <DashboardBody dashboard={dashboard} />
    </div>
  );
};

export default DashboardContainerWithBoundary;
