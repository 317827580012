import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import EntitiesList from '../Omnisearch/EntitiesList';
import CreativesModalResultRendered from './CreativesModalResultRendered';
import styles from './CreativesModal.module.scss';
import CategorizedTags from '../CategorizedTags';

const CitationFooter = ({ entities, tags }) => (
  <div className={styles.citationFooter}>

    {
      (!!entities || !!tags) && (
        <>
          <Typography variant="h4">Citations</Typography>
          <Typography variant="h5">
            from Qloo Insights
          </Typography>
        </>
      )
    }

    {!!entities && (
      <EntitiesList entities={entities} showMini />
    )}

    {!!tags && (
      <div className={styles.resultTagsContainer}>
        {Object.keys(tags).map((category) => (
          <CategorizedTags category={category} tags={tags[category]} limit={99} />
        ))}
      </div>
    )}
  </div>
);

const getTagsByCategory = (tags) => {
  const result = {};
  tags.forEach((tag) => {
    const { category } = tag;
    if (!result[category]) {
      result[category] = [];
    }

    result[category].push(tag);
  });

  return result;
};

const CreativesModalResult = ({
  baseParams,
  entitiesRequest,
  tagsRequest,
  template,
  runnerRequest,
}) => {
  const entities = entitiesRequest.result || [];
  const result = runnerRequest.result || null;
  const tags = tagsRequest.result || [];

  const tagsByCategory = useMemo(() => getTagsByCategory(tagsRequest.result || []), [tagsRequest.result]);

  return (
    <div>
      {!!result && (
        <>
          <div className={styles.resultSection}>
            <div className={styles.resultSectionBody}>
              <CreativesModalResultRendered
                baseParams={baseParams}
                entities={entities}
                tags={tags}
                result={result}
                template={template}
              />
            </div>
          </div>
          <CitationFooter entities={entities} tags={tagsByCategory} />
        </>
      )}
    </div>
  );
};

export default CreativesModalResult;
