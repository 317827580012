import React from 'react';
import clsx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import EntitiesList from '@/components/Omnisearch/EntitiesList';
import CategoryDataError from '../CategoryData/CategoryDataError';
import CategoryDataTags from '../CategoryData/CategoryDataTags';
import styles from '../PanelBodyExplore.module.scss';

const ResumedCategoryData = ({
  categoryColor, tagsResult, category, onSelect, entitiesResult, error, gptResult, tagTypes, tagsFetcher,
}) => {
  const hasError = Boolean(error);
  if (hasError) {
    return <CategoryDataError category={category} error={error} />;
  }

  return (
    <Box
      key={category}
      className={clsx(
        styles.categoryData,
        styles.categoryDataConcise,
      )}
      onClick={onSelect(category)}
    >
      <div
        className={clsx(styles.overlay)}
      />
      <div
        className={clsx(styles.categoryHeader, styles.categoryHeaderConcise)}
        style={{
          '--categoryColor': categoryColor,
        }}
      >
        <Typography color={categoryColor} fontSize={25} fontWeight="800">{urnsToDisplayName(category)}</Typography>
        <Stack
          width={24}
          height={24}
          color="white"
          alignItems="center"
          justifyContent="center"
          padding="7px"
          sx={{
            backgroundColor: categoryColor,
            borderRadius: '50px',
          }}
        >
          <ArrowForwardIos width={10} />
        </Stack>
      </div>

      <span className={styles.categoryDescription}>
        {gptResult}
      </span>

      {tagsResult?.results?.tags && (
        <CategoryDataTags
          category={category}
          tagsResult={tagsResult}
          tagTypes={tagTypes}
          tagsFetcher={tagsFetcher}
        />
      )}
      <div className={styles.separator} />
      <EntitiesList
        resetPadding
        entities={entitiesResult?.results?.entities}
        entitiesGridClassName={styles.entitiesGridClassName}
        showMini
      />
    </Box>
  );
};

export default ResumedCategoryData;
