import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import MapHeatmap from '@/components/MapHeatmap';
import { paramNames } from '@/constants';
import { useDashboardActionsContext } from '../DashboardActionsProvider';

import styles from './DashboardPanel.module.scss';
import getDescribeLocation from '../../../utils/getDescribeLocation';

const PanelBodyHeatmap = ({
  data,
  location,
  panelId,
  heatmapControls,
  isFetching,
  defaultZoom,
  enableCollapsibleControls,
  enableSearch,
}) => {
  const elementRef = useRef(null);
  const [mapKey, setMapKey] = useState(uuidv4());
  const dashboardActions = useDashboardActionsContext();

  const handleControlsChange = (nextHeatmapControls) => {
    const nextSettings = { heatmapControls: nextHeatmapControls };
    dashboardActions.changePanelSettings({ panelId, nextSettings });
  };

  const handleLocationChange = async (nextValue) => {
    const nextLocation = await getDescribeLocation(nextValue);
    const nextParams = { [paramNames.filterLocation]: nextLocation };

    dashboardActions.changePanelParams({ panelId, nextParams });
  };

  const handleLocationClear = () => {
    dashboardActions.deletePanelParam({ panelId, key: paramNames.filterLocation });
  };

  useEffect(() => {
    if (elementRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setMapKey(uuidv4());
      });
      resizeObserver.observe(elementRef.current);
      return () => resizeObserver.disconnect();
    }
    return null;
  }, [elementRef]);

  return (
    <div ref={elementRef} className={styles.heatmapContainer}>
      <MapHeatmap
        key={mapKey}
        data={data}
        location={location}
        controls={heatmapControls}
        onControlsChange={handleControlsChange}
        onLocationChange={handleLocationChange}
        onReset={handleLocationClear}
        isFetching={isFetching}
        defaultZoom={defaultZoom}
        enableCollapsibleControls={enableCollapsibleControls}
        enableSearch={enableSearch}
      />
    </div>
  );
};

export default PanelBodyHeatmap;
