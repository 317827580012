import { useState } from 'react';

const useRequest = (callback) => {
  const [state, setState] = useState({
    error: null,
    isLoading: false,
    result: null,
  });

  const call = async (args) => {
    setState((prevState) => ({
      ...prevState,
      error: null,
      isLoading: true,
    }));

    return callback(args).then(
      (result) => {
        setState({
          error: null,
          isLoading: false,
          result,
        });

        return result;
      },
      (error) => {
        setState({
          error,
          isLoading: false,
          result: null,
        });

        return Promise.reject(error);
      },
    );
  };

  return {
    call,
    ...state,
  };
};

export default useRequest;
