const explainRecsGPTPrompt = ({
  inputEntities, recsEntities, recsCategoryUrn, showMini,
}) => {
  if (!inputEntities || !recsEntities || !recsCategoryUrn) {
    return false;
  }

  const maxLength = showMini ? 150 : 300;

  return [
    `These are subjects related to ${recsCategoryUrn} that an audience likes.`,
    'Your job is to summarize what all of these things have in common.',
    `Here are the ${recsCategoryUrn} entities:`,
    ...inputEntities.map(({ name, disambiguation }) => `${name} (${disambiguation})`),
    'Here are some related tags:',
    ...recsEntities.map(({ name }) => `Tag: ${name}`),
    'Do not use the word "entities" or "attributes", just use conversational English at a high school level.',
    'Think about what these things have in common.',
     `In one concise sentence, explain the overarching themes present in everything (within ${maxLength} characters).`,
     'avoiding any mention of specific names or terms.',
  ]
    .filter((n) => !!n)
    .join('\n');
};

export default explainRecsGPTPrompt;
