import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIdentityBatchedRequestsContext } from '@/contexts/IdentityBatchedRequestsContext';
import { fetchIdentityStart } from '@/store/slices/identitiesSlice';

const Identity = ({ children, identityId }) => {
  const identityBatchedRequests = useIdentityBatchedRequestsContext();

  const dispatch = useDispatch();

  const identity = useSelector((state) => state.identities.byId[identityId]);

  const fetchIfNeeded = () => {
    if (!identity) {
      dispatch(fetchIdentityStart(identityId));
      identityBatchedRequests.queueArg(identityId);
    }
  };

  useEffect(() => {
    fetchIfNeeded();
  }, []);

  return identity ? children({ identity }) : null;
};

export default Identity;
