import brainstorm from './brainstorm';
// import realEstateActionableInsights from './realEstateActionableInsights';
import spokesperson from './spokesperson';
import personas from './personas';
import custom from './custom';
// import tenantAttractionProfile from './tenantAttractionProfile';

export default {
  // realEstateActionableInsights,
  brainstorm,
  spokesperson,
  // tenantAttractionProfile,
  custom,
  personas,
};

// import.meta.glob doesnt seem friendly with hmr, needs a fix

// const templatesImport = import.meta.glob("./*/index.jsx", { eager: true });

// Object.entries(templatesImport).forEach(([path, m]) => {
//   if (typeof m.default !== 'function') {
//     throw new Error(`Template ${path} is not a function`);
//   }
// });

// const templatesByKey = Object.fromEntries(
//   Object.entries(templatesImport)
//     .map(([path, m]) => [path.split('/').slice(-2, -1)[0], m.default()])
//     .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))  // Sort by key name
// );

// export default templatesByKey;
