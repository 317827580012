import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

const MenuItemOpenCreativesModal = ({ onClick, ...props }) => {
  return (
    <MenuItem onClick={onClick} {...props}>
      <ListItemIcon><AutoFixHighIcon /></ListItemIcon>
      <ListItemText>Creatives</ListItemText>
    </MenuItem>
  );
};

export default MenuItemOpenCreativesModal;
