import styles from './Card.module.scss';

const Card = ({
  title, description, actions, children, className,
}) => {
  return (
    <div className={`${styles.card} ${className}`}>
      {(!!title || !!description) && (
        <div className={styles.header}>
          {!!title && <h2 className={styles.title}>{title}</h2>}
          {!!description && <p>{description}</p>}
        </div>
      )}
      <div className={styles.content}>
        {children}
      </div>
      {!!actions && (
        <div className={styles.actions}>
          {actions}
        </div>
      )}
    </div>
  );
};

export default Card;
