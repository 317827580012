import { useState } from 'react';
import { IconButton } from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard } from '@/hooks';
import LocationPopover from '../LocationPopover';
import DashboardPermissionedControl from '../DashboardPermissionedControl';

const DrawControl = ({ location, onChange, onReset }) => {
  const dashboard = useActiveDashboard();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <DashboardPermissionedControl
        dashboardId={dashboard.id}
        requiredPermissions={[USER_ROLES.EDITOR]}
      >
        <IconButton>
          <ZoomOutMapIcon fontSize="small" onClick={handleClick} />
        </IconButton>
      </DashboardPermissionedControl>
      <LocationPopover
        location={location}
        anchorEl={anchorEl}
        onClose={handleClose}
        onChange={onChange}
        onReset={onReset}
      />
    </div>
  );
};

export default DrawControl;
