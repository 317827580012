import { useMemo } from 'react';
import { PERMISSION_MAPPING, USER_ROLES } from '@/constants/roles';
import useUserDashboardIdentity from './useUserDashboardIdentity';
import useIsDashboardOwner from './useIsDashboardOwner';

const useUserDashboardPermissions = (dashboardId) => {
  const userDashboardIdentity = useUserDashboardIdentity(dashboardId);
  const userPermissions = useMemo(() => {
    return userDashboardIdentity?.permissionTagIds || [];
  }, [userDashboardIdentity?.permissionTagIds]);
  const grantedPermissions = userDashboardIdentity?.grantedPermissionTagIds || userPermissions || [];
  const permissionsBitMap = grantedPermissions.reduce((acc, permission) => {
    acc[permission] = true;
    return acc;
  }, {});

  const isUserOwner = useIsDashboardOwner(dashboardId);

  const userHighestRole = useMemo(() => {
    return Object.entries(PERMISSION_MAPPING).reduce(
      (highestRole, [role,
        requiredPermissions]) => (requiredPermissions.every((permission) => userPermissions.includes(permission))
        ? role
        : highestRole),
      USER_ROLES.VIEWER,
    );
  }, [userPermissions]);

  return {
    isUserOwner,
    userPermissions,
    permissionsBitMap,
    userHighestRole,
    hasSharePermission: permissionsBitMap[USER_ROLES.ADMIN] || permissionsBitMap[USER_ROLES.OWNER],
    hasRenamePermission: permissionsBitMap[USER_ROLES.EDITOR],
    hasClonePermission: permissionsBitMap[USER_ROLES.CLONE],
    hasDeletePermission: permissionsBitMap[USER_ROLES.DELETER],
    hasLayoutPermission: permissionsBitMap[USER_ROLES.EDITOR],
  };
};

export default useUserDashboardPermissions;
