import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem } from '@mui/material';

import { filterTypes, paramNames } from '@/constants';
import { useDashboardActionsContext } from '../DashboardActionsProvider';

const TablePagination = ({
  panelId, panelParams, hasMorePages, updateExplorePagination, filterType, explorePagination, hasMoreExplorePages,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const isExploreFilterType = [filterTypes.explore, filterTypes.locationExplorer].includes(filterType);
  const [page, setPage] = useState(
    Math.round(isExploreFilterType ? (explorePagination.offset / explorePagination.take)
      : (panelParams.offset / panelParams.take)) + 1,
  );

  useEffect(() => {
    setPage(Math.round(isExploreFilterType ? (explorePagination.offset / explorePagination.take)
      : (panelParams.offset / panelParams.take)) + 1);
  }, [panelParams, explorePagination]);

  const handlePageChange = ((e, newPage) => {
    setPage(newPage);

    const nextParams = {
      offset: (newPage - 1) * (isExploreFilterType ? explorePagination[paramNames.take] : panelParams.take),
      take: isExploreFilterType ? explorePagination.take : panelParams.take,
    };

    if (isExploreFilterType) {
      updateExplorePagination(nextParams);
      return;
    }

    dashboardActions.changePanelParams({ panelId, nextParams });
  });

  return (
    <Pagination
      count={hasMorePages || hasMoreExplorePages ? page + 2 : page}
      page={page}
      size="small"
      onChange={handlePageChange}
      siblingCount={0}
      boundaryCount={1}
      color="primary"
      // disabled={isLoading}
      renderItem={(item) => {
        // Add ellipsis item before the last page
        if (hasMorePages && item.page === page + 2) {
          return <PaginationItem {...item} disabled type="end-ellipsis" />;
        }
        return <PaginationItem {...item} />;
      }}
    />
  );
};

export default TablePagination;
