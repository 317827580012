import React from 'react';
import MarkdownView from './MarkdownViewer/MarkdownView';

const CreativesModalResultRendered = ({
  baseParams,
  entities,
  tags,
  template,
  result,
}) => {
  const { result: parsedResult, ...rest } = typeof result === 'string' ? { result } : result;
  if (template.render) {
    return template.render({
      baseParams,
      entities,
      result: parsedResult,
      tags,
      ...rest,
    });
  }

  return <MarkdownView>{parsedResult}</MarkdownView>;
};

export default CreativesModalResultRendered;
