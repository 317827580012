import React from 'react';
import SelectedCategoryContentTagsSingle from './SelectedCategoryContentTagsSingle';
import SelectedCategoryContentTagsTabs from './SelectedCategoryContentTagsTabs';
import { tagTypesBySelectedCategory } from '../constants';

const SelectedCategoryContentTags = ({
  baseParams,
  handleSelectSpecificData,
  pagination,
  panel,
  selectedCategory,
  selectedData,
  highlightedSelectedCategory,
}) => {
  const tagTypes = tagTypesBySelectedCategory[selectedCategory] || [];
  if (tagTypes.length) {
    return (
      <SelectedCategoryContentTagsTabs
        baseParams={baseParams}
        handleSelectSpecificData={handleSelectSpecificData}
        pagination={pagination}
        panel={panel}
        selectedCategory={selectedCategory}
        selectedData={selectedData}
        tagTypes={tagTypes}
        highlightedSelectedCategory={highlightedSelectedCategory}
      />
    );
  }

  return (
    <SelectedCategoryContentTagsSingle
      baseParams={baseParams}
      handleSelectSpecificData={handleSelectSpecificData}
      pagination={pagination}
      panel={panel}
      selectedCategory={selectedCategory}
      selectedData={selectedData}
      highlightedSelectedCategory={highlightedSelectedCategory}
    />
  );
};

export default SelectedCategoryContentTags;
