import React from 'react';
import Skeleton from '@/components/Skeleton';
import styles from '../DashboardPanel.module.scss';

const PanelFooterSkeleton = ({ disabled }) => (
  <div className={styles.panelFooter}>
    <Skeleton variant="text" width={100} disabled={disabled} />
    <div className={styles.paginationSkeleton}>
      <Skeleton width={24} height={24} disabled={disabled} />
      <Skeleton width={24} height={24} disabled={disabled} />
      <Skeleton width={24} height={24} disabled={disabled} />
    </div>
    <div className={styles.resizableButtonSkeleton}>
      <Skeleton disabled={disabled} width={24} height={24} />
    </div>
  </div>
);

export default PanelFooterSkeleton;
