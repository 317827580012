import { capitalize } from '@mui/material';
import pluralize from 'pluralize';

const format = (s) => capitalize(pluralize(s.replace(/_/g, ' ')));

const formatTagType = (tagType) => {
  const tagTypeParts = tagType.split(':');
  return tagTypeParts[2] ? format(tagTypeParts[2]) : format(tagType);
};

export default formatTagType;
