import { useMemo } from 'react';
import { Box } from '@mui/material';
import { serializeInsightsParams, WktToGeoJson } from '@/utils';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { filterTypes, paramNames } from '@/constants';
import FetchingOverlay from '@/components/Overlay/FetchingOverlay';
import PanelBodyExplore from './PanelBodyExplore';
import PanelBodyHeatmap from './PanelBodyHeatmap';
import { getHeatmapItems } from './helpers/getDataByFilterType';

const HeatmapLocationExplorer = ({ baseParams, panelParams, ...props }) => {
  const filterType = useMemo(() => filterTypes.heatmap, []);
  const location = panelParams[paramNames.filterLocation];
  const locationWithGeoJson = {
    ...location,
    geoJson: location ? WktToGeoJson(location.value) : null,
  };

  const serializedInsightsParams = useMemo(() => {
    // Force heatmap filter type
    const tempParams = {
      ...panelParams,
      [paramNames.filterType]: filterType,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, filterType, panelParams]);

  const {
    data: panelData,
    isFetching,
    error: panelError,
  } = useGetInsightQuery(serializedInsightsParams);

  const heatmapData = useMemo(
    () => (panelError ? [] : getHeatmapItems(panelData?.results?.heatmap || [])),
    [panelData?.results?.heatmap, panelError],
  );

  return (
    <Box height="600px" position="relative">
      {isFetching && (
        <FetchingOverlay />
      )}
      <PanelBodyHeatmap
        data={heatmapData}
        location={locationWithGeoJson}
        isFetching={isFetching}
        defaultZoom={1.5}
        enableCollapsibleControls
        enableSearch
        {...props}
      />
    </Box>
  );
};

const PanelBodyLocationExplorer = ({
  panelParams, panelId, baseParams, panelSettings, explorePagination, updateHasMoreExplorePages, exploreControls, panel,
}) => {
  const heatmapControls = panelSettings?.heatmapControls;

  const exploreParams = useMemo(() => {
    const customParams = { ...baseParams };
    customParams['signal.location'] = null;

    if (panelParams['filter.location']) {
      customParams['signal.location'] = panelParams['filter.location'];
    }

    return customParams;
  }, [baseParams, panelParams]);

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <HeatmapLocationExplorer
        baseParams={baseParams}
        panelParams={panelParams}
        panelId={panelId}
        heatmapControls={heatmapControls}
      />
      <Box flex="1" position="relative" overflow="auto" marginTop={2}>
        <PanelBodyExplore
          panelId={panelId}
          panel={panel}
          baseParams={exploreParams}
          pagination={explorePagination}
          exploreControls={exploreControls}
          isExploreLocation
          updateHasMoreExplorePages={updateHasMoreExplorePages}
          panelSettings={panelSettings}
        />
      </Box>
    </Box>
  );
};

export default PanelBodyLocationExplorer;
