import form from './formSchema.json';
import formUiSchema from './formUiSchema.json';
import runner from './runner';
import render from './Render';

const Brainstorm = {
  name: '🧠 Marketing Brainstorm',
  description: 'Generate marketing strategies for any product or service',
  form,
  formUiSchema,
  defaultFormData: {
    Categories: [{ name: 'Brands' }],
    instructions: 'Market a line of soap to this audience',
  },
  insights: {
    entities: [
      { category: 'brand', params: { take: 10 } },
    ],
    tags: [
      {
        // Doesn't work
        category: 'brand',
        params: {
          take: 20,
          'filter.parents.types': 'urn:entity:brand',
          'filter.tag.types': 'urn:tag:aesthetic_property:qloo',
        },
      },
    ],
  },
  runner,
  render,
};

export default Brainstorm;
