import { filterTypes } from '@/constants';

export const defaultPageOffset = 0;
export const defaultPageSize = 10;

export const rowSizeOptions = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 45, label: '45' },
];

// Panels that do not require a insight call
export const noInsightPanels = [
  filterTypes.explore,
  filterTypes.locationExplorer,
];
