import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAudiencesQuery } from '@/store/apis/audiencesApi';
import { mergeAudiences } from '@/store/slices/audiencesSlice';
import CANONICAL_TAG from '@/utils/canonicalTag';
import SearchSelect from '../SearchSelect';

const Audiences = ({ onChange, ...props }) => {
  const dispatch = useDispatch();
  const value = useMemo(() => (Array.isArray(props.value) ? props.value : []), [props.value]);
  const [search, setSearch] = useState('');

  const { data: audienceData, isFetching } = useGetAudiencesQuery({
    query: search,
    ...(!search && {
      popularityMax: 1, take: 50,
    }),
    tags: CANONICAL_TAG,
  });

  const onAddHandler = (id) => {
    onChange([...value, id]);
  };

  const onDeleteHandler = (id) => {
    const nextValue = value.filter((valueItem) => valueItem !== id);
    onChange(nextValue);
  };

  useEffect(() => {
    dispatch(mergeAudiences(audienceData?.results.audiences || []));
  }, [audienceData]);

  const handleChangeSearch = (newValue) => {
    setSearch(newValue);
  };

  const handleClear = () => {
    setSearch('');
  };

  const handleChangeValue = (name, checked) => {
    if (!checked) {
      onDeleteHandler(name);
    } else if (checked) {
      onAddHandler(name);
    }
  };

  return (
    <SearchSelect
      resetPadding
      showCheckbox
      search={search}
      name="audience"
      selectedData={value}
      onClear={handleClear}
      isFetching={isFetching}
      onChange={handleChangeValue}
      placeholder="Find a audience"
      ariaLabel="audience selection"
      onChangeSearch={handleChangeSearch}
      data={audienceData?.results?.audiences || []}
    />
  );
};

export default Audiences;
