import MuiButton from '@mui/material/Button';

const InlineButton = (props) => (
  <MuiButton
    variant="inline"
    component="div"
    disableRipple
    {...props}
  />
);

export default InlineButton;
