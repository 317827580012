import { useSelector } from 'react-redux';

const useIsDashboardOwner = (dashboardId) => {
  const { identity } = useSelector((state) => state.app);

  const dashboard = useSelector((state) => state.dashboards.items[dashboardId]);

  let isOwner = false;

  if (dashboard?.history) {
    isOwner = dashboard.history?.some(
      (entry) => entry.operationTagId === 'rop.ins' && entry.identityId === identity.identityId,
    );
  }

  if (!dashboard?.history && dashboard?.events) {
    isOwner = dashboard.events?.some(
      (entry) => entry.operationTagId === 'rop.open' && entry.identityId === identity.identityId,
    );
  }

  return isOwner;
};

export default useIsDashboardOwner;
