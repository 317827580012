import clx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Button from '@/components/Button';
import { paramNames } from '@/constants';
import { serializeInsightsParams } from '@/utils';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import EntitiesList from '@/components/Omnisearch/EntitiesList';
import CategoryDataTags from './CategoryDataTags';
import CategoryDataErrorWrapper from './CategoryDataError';
import validateQueryError from '../../helpers/validateQueryError';
import CategoryDataSkeleton from './CategoryDataSkeleton';
import styles from '../PanelBodyExplore.module.scss';

const CategoryHighLightedData = ({
  category, onSelect, baseParams, tag, tagTypes, tagsFetcher,
}) => {
  const tagsSerializedTagsInsightsParams = useMemo(() => {
    const { tagValue } = tag;
    const tempParams = {
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterTags]: { [tagValue]: 'include' },
      [paramNames.filterParentTypes]: category,
      take: 4,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [tag, baseParams, category]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetInsightQuery(tagsSerializedTagsInsightsParams);

  const serializedInsightsParams = useMemo(() => {
    const { tagValue } = tag;
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.filterTags]: { [tagValue]: 'include' },
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category, tag]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const tags = tagsResult?.results?.tags || [];
  const entities = entitiesResult?.results?.entities || [];

  const {
    result: gptResult,
    isLoading: isLoadingGPT,
    error: gptError,
  } = useGPTExplainRecs({ category, tags, entities });

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError, gptError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError, gptError]);

  const hasError = Boolean(errorData);
  if (hasError) {
    return <CategoryDataErrorWrapper category={category} onSelect={onSelect} error={errorData} />;
  }

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton />;
  }
  const { tagValue } = tag;

  return (
    <Box
      key={category}
      className={clx(
        styles.categoryData,
      )}
      onClick={onSelect(tagValue)}
    >
      <div
        className={clx(styles.overlay)}
      />
      <div
        className={styles.categoryHeader}
      >
        <span>{`${urnsToDisplayName(category)} / ${tag?.name}`}</span>
        <Button color="link"><ArrowForwardIos /></Button>
      </div>
      <span className={styles.categoryDescription}>
        {gptResult}
      </span>
      <div className={styles.separator} />
      <CategoryDataTags
        category={category}
        tagsResult={tagsResult}
        tagTypes={tagTypes}
        tagsFetcher={tagsFetcher}
      />
      <div className={styles.separator} />
      <EntitiesList
        resetPadding
        entities={entitiesResult?.results?.entities}
        entitiesGridClassName={styles.entitiesGridClassName}
        showMini
      />
    </Box>
  );
};

export default CategoryHighLightedData;
