import clx from 'clsx';
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import extractErrorMessage from '@/utils/extractErrors';
import Overlay from '@/components/Overlay';
import styles from '../PanelBodyExplore.module.scss';

const CategoryDataError = ({ category, error }) => {
  const theme = useTheme();
  const errorMessage = extractErrorMessage(error);

  return (
    <Box
      key={category}
      className={clx(
        styles.categoryData,
        styles.categoryDataConcise,
        styles.panelCardOverlayContainer,
      )}
    >
      <Overlay>
        <div className={styles.panelCardErrorContainer}>
          <Typography
            component="pre"
            padding="12px"
            borderRadius={0.5}
            maxWidth={400}
            color={theme.palette.text.primary}
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              backgroundColor: theme.palette.grey['50'],
            }}
          >
            {errorMessage}
          </Typography>
        </div>
      </Overlay>
    </Box>
  );
};

export default CategoryDataError;
