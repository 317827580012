import React, { useEffect, useMemo, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@/components/Table';
import Tag from '@/components/Tag';
import Tooltip from '@/components/Tooltip';
import { paramNames } from '@/constants';
import { affinityColumn, nameColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { serializeInsightsParams } from '@/utils';
import useTagsFetcher from '../useTagsFetcher';
import formatTagType from '../formatTagType';
import { barChartTagTypesBitmap } from '../constants';
import styles from '../PanelBodyExplore.module.scss';

const defaultTagType = 'Default';

const TagCell = ({ row, selectedCategory }) => {
  return (
    <Tag
      noBorder
      noHover
      key={row.tagId}
      useCategoryColor
      {...row.original}
      isSelected={false}
      showSelectedIcon={false}
      type={row?.original?.subtype}
      category={selectedCategory}
    />
  );
};

const getResultsByTagType = ({ tagType, tags, tagsFetcher }) => (tagType === defaultTagType
  ? tags || []
  : tagsFetcher.state[tagType]?.result || []);

const SelectedCategoryContentTagsTabs = ({
  baseParams,
  pagination,
  panel,
  selectedCategory,
  tagTypes,
  highlightedSelectedCategory,
}) => {
  const serializedTagsInsightsParams = useMemo(() => {
    let tempParams = {
      ...panel.params,
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: selectedCategory,
      offset: pagination[paramNames.offset],
      take: pagination[paramNames.take] + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams = {
        ...tempParams,
        [paramNames.filterTags]: { [highlightedSelectedCategory]: 'include' },
      };
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, pagination, panel.params, selectedCategory, highlightedSelectedCategory]);

  const {
    data: tagsResult,
    isLoading: isTagsLoading,
    error: insightsTagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams);

  const tags = useMemo(
    () => (insightsTagsError ? [] : tagsResult?.results?.tags.slice(0, pagination[paramNames.take])),
    [tagsResult?.results?.tags, insightsTagsError, pagination],
  );

  const [tabValue, setTabValue] = useState(defaultTagType);
  const handleTabChange = (e, nextTabValue) => {
    setTabValue(nextTabValue);
  };

  const tagsFetcher = useTagsFetcher();
  useEffect(() => {
    tagsFetcher.fetchByTagTypes({
      baseParams,
      pagination,
      selectedCategory,
      tagTypes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseParams, pagination, selectedCategory]);

  const tagTypesWithDefault = [defaultTagType, ...tagTypes].filter((tagType) => !barChartTagTypesBitmap[tagType]);

  return (
    <div className={styles.tableWithTitle}>
      <div className={styles.tableTitle}>Tags</div>
      <TabContext value={tabValue}>
        <Tabs onChange={handleTabChange} scrollButtons value={tabValue}>
          {tagTypesWithDefault.map((tagType) => {
            const formattedTagType = formatTagType(tagType);
            const label = tagType === defaultTagType ? 'Default' : formattedTagType;
            const result = getResultsByTagType({ tagType, tags, tagsFetcher });
            const disabled = result.length === 0;

            if (disabled) {
              return (
                <Tooltip
                  arrow
                  followCursor
                  placement="top"
                  title={`No results for ${formattedTagType}`}
                >
                  <div>
                    <Tab
                      disabled
                      label={label}
                      value={tagType}
                    />
                  </div>
                </Tooltip>
              );
            }

            return (
              <Tab
                label={label}
                value={tagType}
              />
            );
          })}
        </Tabs>
        {tagTypesWithDefault.map((tagType) => {
          const isLoading = tagType === defaultTagType ? isTagsLoading : tagsFetcher.state[tagType]?.isLoading || false;
          const result = getResultsByTagType({ tagType, tags, tagsFetcher });
          return (
            <TabPanel value={tagType}>
              <Table
                isLoading={isLoading}
                columns={[
                  {
                    ...nameColumn,
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Cell: (props) => <TagCell {...props} selectedCategory={selectedCategory} />,
                  },
                  affinityColumn,
                ]}
                data={result}
              />
            </TabPanel>
          );
        })}
      </TabContext>
    </div>
  );
};

export default SelectedCategoryContentTagsTabs;
