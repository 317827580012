import getDemographicsLabel from './getDemographicsLabel';
import getInterestsLabel from './getInterestsLabel';

const getParamsLabel = (params) => {
  const demographicsLabel = getDemographicsLabel(params);
  const interestsLabel = getInterestsLabel(params);

  return `${demographicsLabel}${interestsLabel ? ` that like ${interestsLabel}` : ''}`;
};

export default getParamsLabel;
