// Spokesperson selection LLM
import { getBaseQueryLabel, allReadableInsights } from '../../lib';

export default async ({
  baseParams, entities, fetchGpt, tags, userData, ...rest
}) => {
  const readableInsights = allReadableInsights({ entities, tags });

  console.log('WOULD RUN', {
    userData, entities, tags, readableInsights, ...rest,
  });

  // TODO: this is a hack because we don't have rich tags for People yet
  const firstPrompt = `
    Make a list of up to 40 traits that describe the people in this list,
    including what country they're famous in,
    what they are known for,
    and what is unique about them.
    Be as expressive as possible.
    Do not use generic adjectives like "smart", "funny", "cute", etc.

    Respond with a comma-separated list of key words, and nothing else.
    ${readableInsights}
  `;
  const adjectives = await fetchGpt({ prompt: firstPrompt });
  console.log(adjectives);

  const prompt = `
      * You are an expert brand strategist that helps select spokespeople based on Qloo data.
      * You are given an audience, and a list of subjects that are relevant to that audience, according to Qloo data.
      * Then, based on the people and qualities that are relevant to the audience, and their interests, talk about the best spokespeople for the audience for ${userData.instructions}.

      * Title the document creatively, in less than 10 words, that encapsulates the objective of the task: Spokesperson selection for ${userData.instructions} for the audience ${getBaseQueryLabel(baseParams)}
      * First write a thorough section about what all of these people and their qualities have in common.
      * Then, write a section explaining what traits to look for in any spokesperson for the audience, and what traits to avoid.
      * Finally, write a section explaining how all of this is relevant to the audience's interests combined with ${userData.instructions}.

      Explain your reasoning for each step.

      For this audience: ${getBaseQueryLabel(baseParams)}
      Qloo thinks that these people are relevant for the audience:
      ${readableInsights}
      and these adjectives describe the common traits of the people in this list: ${adjectives}
      The objective is to find a spokesperson for ${userData.instructions}.

      Respond in markdown. Make key words bold. Use # For the title, ## for each section header.
    `;

  const result = await fetchGpt({ prompt });

  return {
    adjectives,
    result,
  };
};
