import React, { useState } from 'react';
import {
  Popover, MenuItem, ListItemIcon, ListItemText,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DoneIcon from '@mui/icons-material/Done';
import useScrollBlock from '@/hooks/useScrollBlock';
import { TYPES_FILTERS } from './consts';

export const ownershipOptions = [
  TYPES_FILTERS.OWNED_BY_ANYONE,
  TYPES_FILTERS.OWNED_BY_ME,
  TYPES_FILTERS.NOT_OWNED_BY_ME,
  TYPES_FILTERS.MY_FAVORITES,
];

const SortButton = ({ selectedOption, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    blockScroll();
  };

  const handleClose = () => {
    setAnchorEl(null);
    allowScroll();
  };

  const handleSelect = (option) => {
    onSelect(option);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <div>
      <IconButton sx={{ borderRadius: 'var(--border-radius)' }} onClick={handleClick}>
        <Typography variant="body1" color="GrayText">{selectedOption}</Typography>
        <ArrowDropDownIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box pt={0.7} pb={0.7}>
          {ownershipOptions?.map((option) => (
            <MenuItem
              key={option}
              selected={option === selectedOption}
              onClick={() => handleSelect(option)}
            >
              <ListItemIcon>
                {option === selectedOption
                  && <DoneIcon color="disabled" />}
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </div>
  );
};

export default SortButton;
