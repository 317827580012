import React, { useMemo } from 'react';
import Tag from '@/components/Tag';
import { paramNames } from '@/constants';
import { affinityColumn, nameColumn } from '@/screens/Dashboard/DashboardPanel/helpers/getColumns';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import { serializeInsightsParams } from '@/utils';
import TableWithTitle from '../TableWithTitle';

const TagCell = ({ row, selectedCategory }) => {
  return (
    <Tag
      noBorder
      noHover
      key={row.tagId}
      useCategoryColor
      {...row.original}
      isSelected={false}
      showSelectedIcon={false}
      type={row?.original?.subtype}
      category={selectedCategory}
    />
  );
};

const SelectedCategoryContentTagsSingle = ({
  baseParams,
  handleSelectSpecificData,
  pagination,
  panel,
  selectedCategory,
  selectedData,
  highlightedSelectedCategory,
}) => {
  const serializedTagsInsightsParams = useMemo(() => {
    let tempParams = {
      ...panel.params,
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: selectedCategory,
      offset: pagination[paramNames.offset],
      take: pagination[paramNames.take] + 1,
    };

    if (highlightedSelectedCategory) {
      tempParams = {
        ...tempParams,
        [paramNames.filterTags]: { [highlightedSelectedCategory]: 'include' },
      };
    }

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, pagination, panel.params, selectedCategory, highlightedSelectedCategory]);

  const {
    data: tagsResult,
    isLoading: isTagsLoading,
    error: insightsTagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams, {
    skip: selectedData === 'ENTITIES',
  });

  const tags = useMemo(
    () => (insightsTagsError ? [] : tagsResult?.results?.tags.slice(0, pagination[paramNames.take])),
    [tagsResult?.results?.tags, insightsTagsError, pagination],
  );

  return (
    <TableWithTitle
      isLoading={isTagsLoading}
      title="Tags"
      columns={[
        {
          ...nameColumn,
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (props) => <TagCell {...props} selectedCategory={selectedCategory} />,
        },
        affinityColumn,
      ]}
      data={tags || []}
      onSelect={handleSelectSpecificData(selectedData === 'TAGS' ? null : 'TAGS')}
    />
  );
};

export default SelectedCategoryContentTagsSingle;
