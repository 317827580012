import dot from 'dot-object';
import { paramNames } from '@/constants';
import ReactMarkdown from 'react-markdown';

const schema = `{
  "strict": True,
  "required": [
      "summary",
      "demographicsBreakdown",
      "description",
      "interests",
      "ideal_amenities",
  ],
  "additionalProperties": False,
  "properties": {
      "summary": {
          "type": "string",
          "$comment": "A summary of the persona."
      },
      "demographicBreakdown": {
          "type": "string",
          "$comment": "gender: male, female, no preference\
          age range: <= 24, 25-29, 30-34, 35-44, 45-54, 55+ (can be multiple ranges)\
          income level, propensity to spend, etc"
      },
      "description": {
          "type": "object",
          "$comment": "Could you write up a paragraph that describes the insights, \
          a paragraph that describes projected passions, and a paragraph for actionable insights? \
          Each paragraph should be no more than 5 sentences. Please make the writing conversational,\
          engaging, and clear, and ask it to avoid jargon",
          "properties": {
              "insights": {
                  "type": "string",
                  "$comment": "general insights about this persona"
              },
              "projectedPassions": {
                  "type": "string",
                  "$comment": "examples could be interests, experiences, local activities"
              },
              "actionableInsightsRealEstate": {
                  "type": "string",
                  "$comment": "given these personas what should a real estate developer focus on"
              },
              "actionableInsightsCPG": {
                  "type": "string",
                  "$comment": "given these personas what should a CPG company focus on"
              },
          }
      },
      "percentage": {
          "type": "number",
          "$comment": "Percentage breakdown of the persona to represent the genre tags and entities.\
          the total percentage across all personas should equal 100"
      },
      "interests": {
          "type": "object",
          "$comment": "Descriptions of the cultural interests for the persona",
          "properties": {
              "lifestyleBrands": {
                  "type": "string",
                  "$comment": "A description of the types of lifestyle brands that this persona has an affinity towards"
              },
              "frequentPlaces": {
                  "type": "string",
                  "$comment": "A description of the types of places that this persona has an affinity towards"
              },
              "entertainment": {
                  "type": "string",
                  "$comment": "A description of the types of entertainment that this persona has an affinity towards"
              },
              "readingPodcasts": {
                  "type": "string",
                  "$comment": "A description of the types of reading, books, and podcasts that this persona has an affinity towards"
              },
              "music": {
                  "type": "string",
                  "$comment": "A description of the types of music that this persona has an affinity towards"
              }
          },
      },
      "idealAmenities": {
          "type": "array",
          "$comment": "Descriptions of the ideal amenities for the persona",
          "items": {
              "type": "string",
              "description": "string",
              "$comment": "the type of ammenity and a description"
          }
      }
  }
}`;

const mapEntity = (entity) => `Entity: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const mapTag = (entity) => `Tag Genre: ${entity.name}, Subject: ${entity.subtype}, Score: ${entity.query.affinity}`;

const properties = [
  { key: 'summary', label: 'Summary', placeholder: 'No summary available' },
  { key: 'demographicBreakdown', label: 'Demographics', placeholder: 'No demographic breakdown available' },
  {
    label: 'Description',
    items: [
      { key: 'description.insights', label: 'Insights', placeholder: 'No insights available' },
      { key: 'description.projectedPassions', label: 'Passions', placeholder: 'No passions available' },
      { key: 'description.actionableInsightsRealEstate', label: 'Actionable Insights Real Estate', placeholder: 'No actionable insights for real estate' },
      { key: 'description.actionableInsightsCPG', label: 'Actionable Insights CPG', placeholder: 'No actionable insights for CPG' },
    ],
  },
  {
    label: 'Interests',
    items: [
      { key: 'interests.lifestyleBrands', label: 'Lifestyle Brands', placeholder: 'No lifestyle brands available' },
      { key: 'interests.frequentPlaces', label: 'Frequent Places', placeholder: 'No frequent places available' },
      { key: 'interests.entertainment', label: 'Entertainment', placeholder: 'No entertainment data available' },
      { key: 'interests.readingPodcasts', label: 'Reading Podcasts', placeholder: 'No reading or podcast data available' },
      { key: 'interests.music ', label: 'Music', placeholder: 'No music data available' },
    ],
  },
  {
    key: 'idealAmenities', format: (value) => (value?.length ? value.map((v) => `* ${v}`).join('\n') : null), label: 'Ideal Amenities', placeholder: 'No ideal amenities listed',
  },
];

const mapProperty = (sectionPrefix, persona) => (property) => {
  const value = dot.pick(property.key, persona);
  const formattedValue = property.format ? property.format(value) : value;
  return `${sectionPrefix} ${property.label}\n${formattedValue || property.placeholder}`;
};

const getResultMarkdown = (personasResult) => {
  console.log('personasResult', personasResult);
  return personasResult.map((persona, i) => {
    return `# Persona ${i + 1}\n${properties.map((property) => {
      if (property.items) {
        return `## ${property.label}\n${property.items.map(mapProperty('###', persona)).join('\n')}`;
      }

      return mapProperty('##', persona)(property);
    }).join('\n')}`;
  }).join('\n');
};

const personas = {
  name: 'Personas',
  description: 'Personas derived from retool query',
  insights: {
    entities: [
      { category: 'artist', params: { [paramNames.take]: 5 } },
      { category: 'book', params: { [paramNames.take]: 5 } },
      { category: 'brand', params: { [paramNames.take]: 5 } },
      { category: 'movie', params: { [paramNames.take]: 5 } },
      { category: 'person', params: { [paramNames.take]: 5 } },
      { category: 'place', params: { [paramNames.take]: 5 } },
      { category: 'podcast', params: { [paramNames.take]: 5 } },
      { category: 'tv_show', params: { [paramNames.take]: 5 } },
    ],
    tags: [
      { category: 'artist', params: { [paramNames.take]: 5 } },
      { category: 'book', params: { [paramNames.take]: 5 } },
      { category: 'brand', params: { [paramNames.take]: 5 } },
      { category: 'movie', params: { [paramNames.take]: 5 } },
      { category: 'person', params: { [paramNames.take]: 5 } },
      { category: 'place', params: { [paramNames.take]: 5 } },
      { category: 'podcast', params: { [paramNames.take]: 5 } },
      { category: 'tv_show', params: { [paramNames.take]: 5 } },
    ],
  },
  render: ({ result }) => {
    const markdown = getResultMarkdown(JSON.parse(result.slice(7, -3) || '[]'));
    return (
      <ReactMarkdown>
        {markdown}
      </ReactMarkdown>
    );
  },
  runner: async ({
    baseParams, entities, fetchGpt, tags,
  }) => {
    const prompt = `
      given these breakdowns of affinity scores for genre tags and entities,
      ${baseParams[paramNames.signalLocation] ? ` for the location of ${baseParams[paramNames.signalLocation].label}` : ''}
      give 10 persona that represents this taste
      tag inputs: ${tags.map(mapTag).join(' ')}
      entity inputs: ${entities.map(mapEntity).join(' ')}
      update this to add ideal amenities
      each persona should follow this schema ${schema}
      do not name the personas
      output as json array
    `;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default personas;
