import { filterTypes } from '@/constants';

function getPanelHeaderTitle(filterType) {
  switch (filterType) {
    case filterTypes.explore:
      return 'Summary overview';
    case filterTypes.heatmap:
      return 'Heatmap';
    case filterTypes.locationExplorer:
      return 'Location Explorer';
    default:
      return 'Entity Correlations';
  }
}

export default getPanelHeaderTitle;
