import { formatMilliseconds } from 'format-ms';
import { useRef, useState } from 'react';
import { isObject } from '@/utils';

const filterArgs = (args) => {
  if (isObject(args)) {
    return Object.keys(args)
      .filter((key) => typeof args[key] !== 'function')
      .reduce((obj, key) => ({ ...obj, [key]: args[key] }), {});
  }
  return args;
};

const useOutput = () => {
  const countRef = useRef(0);
  const [state, setState] = useState([]);

  const reset = () => {
    setState([]);
  };

  const addEntry = ({ collapsible, index, text }) => {
    setState((prevState) => {
      const nextItems = [
        ...prevState,
        {
          collapsible: collapsible || null,
          index,
          text,
          timestamp: new Date().valueOf(),
        },
      ];

      return nextItems;
    });
  };

  const getIndex = () => {
    const index = countRef.current;
    countRef.current = index + 1;
    return index;
  };

  const wrapFn = (fn, title = '') => {
    return async (args) => {
      const index = getIndex();
      const start = (new Date()).valueOf();
      addEntry({ index, text: `[START] ${title}`, collapsible: { label: 'Show arguments', json: filterArgs(args) } });
      try {
        const result = await fn(args);
        const end = (new Date()).valueOf();
        const took = formatMilliseconds(end - start);
        addEntry({ index, text: `[Took: ${took}] [SUCCESS] ${title}`, collapsible: { label: 'Show results', json: result } });
        return result;
      } catch (e) {
        const end = (new Date()).valueOf();
        const took = formatMilliseconds(end - start);
        addEntry({ index, text: `[Took: ${took}] [ERROR] ${title} with error: ${e.toString()}` });
        throw e;
      }
    };
  };

  return {
    reset,
    state,
    wrapFn,
  };
};

export default useOutput;
