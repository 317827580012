import { createSlice } from '@reduxjs/toolkit/react';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    ownership: {},
    user: null,
    identity: null,
    presenceUsers: {},
    isDarkMode: false,
  },
  reducers: {
    setIdentity: (state, action) => {
      return {
        ...state,
        identity: action.payload,
      };
    },
    setOwnership: (state, action) => {
      return {
        ...state,
        ownership: {
          ...state.ownership,
          [action.payload.key]: action.payload.userId,
        },
      };
    },
    clearOwnership: (state, action) => {
      return {
        ...state,
        ownership: {
          ...state.ownership,
          [action.payload.key]: null,
        },
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    setPresenceUsers: (state, action) => {
      return {
        ...state,
        presenceUsers: action.payload,
      };
    },
    toggleDarkMode: (state) => {
      return {
        ...state,
        isDarkMode: !state.isDarkMode,
      };
    },
  },
});

export const {
  setIdentity,
  setOwnership,
  clearOwnership,
  setUser,
  setPresenceUsers,
  toggleDarkMode,
} = appSlice.actions;
