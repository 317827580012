import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useScrollBlock } from '@/hooks';
import Modal from '../Modal';
import Stepper from '../Stepper';
import CreativesModalStepOne from './CreativesModalStepOne';
import CreativesModalStepTwo from './CreativesModalStepTwo';
import styles from './CreativesModal.module.scss';
import templatesByKey from './templates';
import getMissingRequiredParams from './getMissingRequiredParams';

const CreativesModal = ({ baseParams, close }) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [activeIndex, setActiveIndex] = useState(0);
  const selectedTemplateKeyState = useState(null);
  const [selectedTemplateKey] = selectedTemplateKeyState;

  const userDataState = useState({});
  const [userData] = userDataState;

  const formRef = useRef(null);

  const steps = useMemo(() => [
    {
      label: 'Setup',
      isDisabled: !selectedTemplateKey,
      render: () => (
        <CreativesModalStepOne
          baseParams={baseParams}
          formRef={formRef}
          selectedTemplateKeyState={selectedTemplateKeyState}
          userDataState={userDataState}
        />
      ),
      validate: () => {
        const form = formRef.current;
        if (form) {
          const formIsValid = form.validateForm();
          if (!formIsValid) {
            return false;
          }
        }

        const template = templatesByKey[selectedTemplateKey];
        if (template?.required) {
          const missingRequiredParams = getMissingRequiredParams(baseParams, template.required);
          if (missingRequiredParams.length) {
            return false;
          }
        }

        return true;
      },
    },
    {
      label: 'Result',
      render: () => (
        <CreativesModalStepTwo
          baseParams={baseParams}
          template={templatesByKey[selectedTemplateKey]}
          userData={userData}
        />
      ),
    },
  ], [selectedTemplateKey, userData]);

  useEffect(() => {
    blockScroll();

    return () => {
      allowScroll();
    };
  }, []);

  return (
    <Modal
      isOpen
      close={close}
      title="Creatives"
      renderContent={() => (
        <div className={styles.container}>
          <Stepper
            activeIndex={activeIndex}
            className={styles.stepper}
            containerClassName={styles.stepperContent}
            onChange={setActiveIndex}
            onSubmit={close}
            steps={steps}
            submitLabel="Done"
          />
        </div>
      )}
    />
  );
};

export default CreativesModal;
