import Alert from '@mui/material/Alert';
import React, { useMemo } from 'react';
import fieldByParamName from '@/constants/fieldByParamName';

const NICE_NAMES = {
  'signal.location': 'Location',
};
const niceParamNames = (paramNames) => paramNames.map((paramName) => NICE_NAMES[paramName] || paramName);

const CreativesModalMissingParams = ({ missingRequiredParams }) => {
  const labels = useMemo(
    () => missingRequiredParams.map((paramName) => fieldByParamName[paramName]?.label || paramName),
    [missingRequiredParams],
  );
  return (
    <Alert severity="error">
      This feature requires the parameters <strong>{niceParamNames(labels).join(', ')}</strong> on the dashboard.
    </Alert>
  );
};

export default CreativesModalMissingParams;
