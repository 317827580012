import React from 'react';
import Button from '@/components/Button';
import TooltipWarning from '@/components/Tooltip/TooltipWarning';

const DashboardOpenTabs = ({ isAuthorized, onClick, openDashboardCount }) => {
  return (
    <TooltipWarning placement="top-start" message="You don't have permission to access a dashboard." conditionalRender={!isAuthorized}>
      <div>
        <Button disabled={!isAuthorized} onClick={onClick}>
          View my open tabs ({openDashboardCount})
        </Button>
      </div>
    </TooltipWarning>
  );
};

export default DashboardOpenTabs;
