import { paramNames } from '@/constants';
import { getDemographicsLabel, getParamsLabel } from '@/utils';

const custom = {
  name: 'Custom',
  features: {
    categorySelect: true,
    interactive: false,
  },
  form: {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      instructions: {
        type: 'string',
        title: 'Question',
        description: "What would you like to know about this audience's taste?",
      },
    },
    required: ['instructions'],
  },
  runner: async ({ baseParams, fetchGpt, userData }) => {
    const demographicsLabel = getDemographicsLabel(baseParams);
    const paramsLabel = getParamsLabel(baseParams);
    const prompt = `For people who are considered: ${paramsLabel} ${demographicsLabel}${baseParams[paramNames.signalLocation] ? ` and live in ${baseParams[paramNames.signalLocation].label}` : ''}. ${userData.instructions}`;

    try {
      const gptResult = await fetchGpt({ prompt });
      return gptResult;
    } catch (e) {
      return 'Error';
    }
  },
};

export default custom;
