import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Identity from '@/components/Identity';
import Table from '@/components/Table';
import { useUserDashboardPermissions } from '@/hooks';
import ErrorBoundary from '@/components/ErrorBoundary';
import { formatRangeDate } from '../helpers/dashboardHelpers';
import { ReactComponent as Logo } from '../../../../../../src/components/LogoSm.svg';
import DashboardUserChipAvatar from '../DashboardChipAvatar/DashboardUserChipAvatar';
import PermissionChips from '../PermissionChips';
import DashboardUserChipAvatarGroup from '../DashboardChipAvatar/DashboardUserChipAvatarGroup';
import styles from './DashboardTable.module.scss';
import MoreOptionsDashboard from '../MoreOptionsDashboard';
import TableSkeleton from '../Skeleton/DashboardTableSkeleton';

const DashboardTable = ({
  data,
  handleClickToRedirect,
  pageInfo,
  paginationTable,
  onPageChange,
  isLoadingPagination,
}) => {
  const columns = useMemo(() => [
    {
      accessorKey: 'thumbnail',
      header: 'View',
      Cell: ({ row }) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            className={styles.thumbnailBox}
            sx={row?.original?.thumbnail && { backgroundImage: `url(${row.original.thumbnail})` }}
          >
            {!data?.thumbnail && <Logo className="logoSvgTable" />}
          </Box>
        </Box>
      ),
      size: 8,
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'title',
      header: 'Title',
      Cell: ({ row }) => (
        <Typography variant="subtitle2" className={styles.titleText}>
          {row?.original?.title || `Untitled-${row?.original?.id}`}
        </Typography>
      ),
    },
    {
      accessorKey: 'summary',
      header: 'Summary',
      Cell: () => (
        <Typography variant="subtitle2" className={styles.summaryText}>
          No dashboard description.
        </Typography>
      ),
    },
    {
      accessorFn: (row) => row?.history?.[0]?.effectiveRange,
      header: 'Created at',
      Cell: ({ row }) => {
        const createdAt = row?.original?.history?.[0]?.effectiveRange;
        return (
          <Typography variant="subtitle2" className={styles.createdAtText}>
            {createdAt && formatRangeDate(createdAt)}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => !!row?.history?.length && row.history[row.history.length - 1]?.effectiveRange,
      header: 'Updated at',
      Cell: ({ row }) => {
        const updatedAt = !!row.original.history?.length
          && row.original.history[row.original.history.length - 1].effectiveRange;

        return (
          <Typography variant="subtitle2" className={styles.updatedAtText}>
            {updatedAt && formatRangeDate(updatedAt)}
          </Typography>
        );
      },
    },
    {
      accessorFn: (row) => !!row?.history?.length && row.history[row.history.length - 1],
      header: 'Last edited by',
      Cell: ({ row }) => {
        const lastHistoryItem = (
          !!row?.original?.history?.length && row.original.history[row.original.history.length - 1]
        ) || {};
        return lastHistoryItem && (
          <Identity identityId={lastHistoryItem.identityId}>
            {({ identity }) => (
              <DashboardUserChipAvatar
                userId={identity?.identityId}
                label={identity?.firstName || identity?.fullName}
                email={identity?.loginEmailAddress}
                isShowLabel
              />
            )}
          </Identity>
        );
      },
    },
    {
      accessorKey: 'identities',
      header: 'Shared with',
      Cell: ({ row }) => (
        <Box display="flex" flexDirection="row">
          <DashboardUserChipAvatarGroup
            data={
              row?.original?.identityLinks
                ?.filter((el) => !row?.original?.history?.some(
                  (historyItem) => historyItem?.operationTagId === 'rop.ins'
                      && historyItem?.identityId === el?.identityId,
                ))
            }
            className={styles.avatarGroup}
          />
        </Box>
      ),
    },
    {
      accessorKey: 'permissionTagIds',
      header: 'Permissions',
      Cell: ({ row }) => {
        const { isUserOwner, userHighestRole } = useUserDashboardPermissions(row?.original?.id);
        return (
          <PermissionChips
            isUserOwner={isUserOwner}
            role={userHighestRole}
          />
        );
      },
    },
    {
      accessorKey: 'options',
      header: '',
      Cell: ({ row }) => {
        return (
          <Box display="flex" alignItems="center" justifyContent="center">
            <MoreOptionsDashboard dashboard={row?.original} showFavorite />
          </Box>
        );
      },
      size: 10,
      enableSorting: false,
      enableColumnActions: false,
    },
  ], [handleClickToRedirect, data.thumbnail]);

  return (
    <Table
      columns={columns}
      data={data}
      enablePagination
      enableTopToolbar
      enableBottomToolbar
      onRowClick={handleClickToRedirect}
      pagination={paginationTable}
      onPaginationChange={onPageChange}
      isLoading={isLoadingPagination}
      rowCount={pageInfo?.totalCount}
    />
  );
};

const BrokenTable = () => {
  return (
    <Box position="relative">
      <Typography
        variant="subtitle2"
        sx={{
          color: 'red',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        Error loading table
      </Typography>
      <TableSkeleton />
    </Box>
  );
};

const WrappedDashboardTable = (props) => {
  return (
    <ErrorBoundary FallbackComponent={BrokenTable}>
      <DashboardTable {...props} />
    </ErrorBoundary>
  );
};

export default WrappedDashboardTable;
