import React from 'react';
import { useTheme } from '@mui/material';
import Skeleton from '@/components/Skeleton';

import styles from './DashboardTabsSkeleton.module.scss';

const TabButtonSkeleton = () => {
  const theme = useTheme();

  return (
    <Skeleton
      width="38px"
      height="38px"
      borderRadius={0}
      baseColor={theme.palette.primary.light}
      highlightColor={theme.palette.primary.main}
      className={styles.actionButton}
    />
  );
};

export default TabButtonSkeleton;
