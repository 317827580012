import clx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import { ArrowForwardIos } from '@mui/icons-material';
import React, { useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { paramNames } from '@/constants';
import { serializeInsightsParams } from '@/utils';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import EntitiesList from '@/components/Omnisearch/EntitiesList';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import CategoryDataErrorWrapper from '../CategoryData/CategoryDataError';
import validateQueryError from '../../helpers/validateQueryError';
import CategoryDataSkeleton from '../CategoryData/CategoryDataSkeleton';
import CategoryDataTags from '../CategoryData/CategoryDataTags';
import styles from '../PanelBodyExplore.module.scss';

const ResumedCategoryHighlightedData = ({
  category, onSelect, baseParams, tag, categoryColor, tagTypes, tagsFetcher,
}) => {
  const { tagValue } = tag;

  const tagsSerializedTagsInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: category,
      [paramNames.filterTags]: { [tagValue]: 'include' },
      take: 4,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [tagValue, category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetInsightQuery(tagsSerializedTagsInsightsParams);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.filterTags]: { [tagValue]: 'include' },
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category, tagValue]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const tags = tagsResult?.results?.tags || [];
  const entities = entitiesResult?.results?.entities || [];
  const {
    result: gptResult,
  } = useGPTExplainRecs(
    {
      category, tags, entities, showMini: true,
    },
    {
      skip: isLoadingEntities
        || isLoadingTags,
    },
  );

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError]);

  const hasError = Boolean(errorData);
  if (hasError) {
    return <CategoryDataErrorWrapper category={category} onSelect={onSelect} error={errorData} />;
  }

  if (isLoadingEntities || isLoadingTags) {
    return <CategoryDataSkeleton conciseView />;
  }

  return (
    <Box
      key={category}
      className={clx(
        styles.categoryData,
        styles.categoryDataConcise,
      )}
      onClick={onSelect(tagValue)}
    >
      <div
        className={clx(styles.overlay)}
      />
      <div
        className={clx(styles.categoryHeader, styles.categoryHeaderConcise)}
        style={{
          '--categoryColor': categoryColor,
        }}
      >
        <Typography
          color={categoryColor}
          fontSize={25}
          fontWeight="800"
        >
          {`${urnsToDisplayName(category)} / ${tag?.name}`}
        </Typography>
        <Stack
          width={24}
          height={24}
          color="white"
          alignItems="center"
          justifyContent="center"
          padding="7px"
          sx={{
            backgroundColor: categoryColor,
            borderRadius: '50px',
          }}
        >
          <ArrowForwardIos width={10} />
        </Stack>
      </div>

      <span className={styles.categoryDescription}>
        {gptResult}
      </span>

      {tags && (
        <CategoryDataTags
          category={category}
          tagsResult={tagsResult}
          tagTypes={tagTypes}
          tagsFetcher={tagsFetcher}
        />
      )}

      <div className={styles.separator} />
      <EntitiesList
        resetPadding
        entities={entities}
        entitiesGridClassName={styles.entitiesGridClassName}
        showMini
      />
    </Box>
  );
};

export default ResumedCategoryHighlightedData;
