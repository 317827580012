import clsx from 'clsx';
import React from 'react';
import styles from './Stepper.module.scss';
import Button from '../Button';

const Stepper = ({
  activeIndex,
  className,
  containerClassName,
  isLoading,
  onChange,
  onReset,
  onSubmit,
  submitLabel,
  steps,
}) => {
  const isLastStep = activeIndex === steps.length - 1;

  const activeStep = steps[activeIndex];

  const next = () => {
    onChange(activeIndex + 1);
  };

  const prev = () => {
    onChange(Math.max(activeIndex - 1, 0));
  };

  const handleNext = () => {
    if (activeStep?.validate) {
      if (activeStep.validate()) {
        next();
      }
    } else {
      next();
    }
  };

  if (!activeStep) {
    return null;
  }

  return (
    <div className={clsx({
      [styles.container]: true,
      [className]: className,
    })}
    >
      <div className={clsx({
        [styles.content]: true,
        [containerClassName]: containerClassName,
      })}
      >
        {activeStep.render()}
      </div>
      <div className={styles.footer}>
        <div className={styles.dots}>
          {steps.map((step, i) => (
            <div className={styles.dot} key={i}>
              <div className={styles.dotNumberContainer}>
                <div
                  className={clsx({
                    [styles.dotNumber]: true,
                    [styles.dotNumberComplete]: i <= activeIndex,
                  })}
                  key={i}
                >
                  {i + 1}
                </div>
              </div>
              <div
                className={clsx({
                  [styles.dotBar]: true,
                  [styles.dotBarComplete]: i <= activeIndex,
                })}
              />
              {i === activeIndex ? (
                <div className={styles.dotLabel}>{steps[i].label}</div>
              ) : null}
              {i < steps.length - 1 ? (
                <div
                  className={clsx({
                    [styles.dotBar]: true,
                    [styles.dotBarComplete]: i < activeIndex,
                  })}
                />
              ) : null}
            </div>
          ))}
        </div>
        <div className={`stepper__buttons ${styles.actions} `}>
          {onReset ? <Button onClick={onReset} variant="outline">Reset</Button> : null}
          {activeIndex > 0 ? <Button onClick={prev}>Back</Button> : null}
          <Button disabled={activeStep.isDisabled} isLoading={isLoading} onClick={isLastStep ? onSubmit : handleNext}>
            {isLastStep ? submitLabel || 'Submit' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
