import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useDebounce from '@v1/lib/useDebounce';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategorySelect from '../CategorySelect';
import OmnisearchResults from './OmnisearchResults';
import OmnisearchSearchInput from './OmnisearchSearchInput';
import Chip from '../Chip';
import EntityText from '../EntityText';
import useOmnisearchResults from './useOmnisearchResults';
import styles from './Omnisearch.module.scss';

const OmnisearchInner = ({ downshift, onChange, value }) => {
  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    isOpen,
    toggleMenu,
  } = downshift;

  const inputRef = useRef(null);
  const inputProps = getInputProps({ ref: inputRef });

  const entityById = useSelector((state) => state.entities.byId);

  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');

  const debouncedSearch = useDebounce(search, 250);

  const handleSearchInputChange = (inputValue, e) => {
    inputProps.onChange(e);
    setSearch(inputValue);
  };

  const omnisearchResults = useOmnisearchResults({ search: debouncedSearch, category });

  const handleDeleteValueByIndex = (index) => () => {
    const nextValue = [...value];
    nextValue.splice(index, 1);
    onChange(nextValue);
  };

  const handleClickSearchInput = () => {
    toggleMenu();
    if (!isOpen) {
      inputRef.current?.focus();
    }
  };

  const handleSelectCategory = (urn) => {
    setCategory(urn);
  };

  return (
    <>
      <div className={styles.fieldsContainer}>
        <OmnisearchSearchInput
          inputOnChange={handleSearchInputChange}
          inputProps={inputProps}
          isLoading={omnisearchResults.isLoading}
          onClick={handleClickSearchInput}
          search={search}
        />
        <CategorySelect value={category} onChange={handleSelectCategory} className={styles.categorySelect} />
      </div>
      {(value && !!value.length) && (
        <div className={styles.chipsContainer}>
          {value.map((item, i) => {
            const entity = entityById[item.id];

            return (
              <Chip
                key={item.id}
                onDelete={handleDeleteValueByIndex(i)}
                variant="contained"
                className={styles.chip}
                style={{
                  '--dynamic-category-color': (entity?.types || []).length > 0
                    ? `var(--category-${formatCategoryUrnToCssVar(entity.types[0])})` : 'hsl(var(--gray-ghost))',
                  '--dynamic-category-secondary-color':
                    (entity?.types || []).length > 0
                      ? `var(--category-${formatCategoryUrnToCssVar(entity.types[0])}-secondary)`
                      : 'hsl(var(--gray-light))',
                }}
                deleteIconClassName={styles.chipDeleteIcon}
              >
                <EntityText className={styles.chipText} item={item} />
              </Chip>
            );
          })}
        </div>
      )}
      {debouncedSearch && (
        <OmnisearchResults
          getItemProps={getItemProps}
          getMenuProps={getMenuProps}
          omnisearchResults={omnisearchResults}
          value={value}
        />
      )}
    </>
  );
};

export default OmnisearchInner;
