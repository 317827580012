/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import {
  Card, Typography, Box, CardContent, CardHeader, Tooltip,
} from '@mui/material';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import GroupsIcon from '@mui/icons-material/Groups';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { formatRangeDate } from '../helpers/dashboardHelpers';
import { ReactComponent as Logo } from '../../../../../../src/components/LogoSm.svg';
import PermissionChips from '../PermissionChips';
import MenuItemFavorite from '../MenuItemFavorite';
import getTitle from '../../Dashboard/helpers/getTitle';
import MoreOptionsDashboard from '../MoreOptionsDashboard';
import SharedWith from '../ShareWith/SharedWith';
import { useUserDashboardPermissions } from '@/hooks';
import useUserIdentity from '@/screens/HomePage/hooks/useUserIdentity';
import styles from './DashboardCard.module.scss';

const DashboardCard = ({ data, onClick }) => {
  const loggedUserIdentity = useUserIdentity();
  const { isUserOwner, userHighestRole } = useUserDashboardPermissions(data.id);
  const isDashboardShared = isUserOwner && data.identities?.length > 1;

  const onClickRedirect = () => {
    onClick(data.id);
  };

  return (
    <Card className={styles.card}>
      <CardHeader
        action={<MoreOptionsDashboard dashboard={data} />}
        title={(
          <Box display="flex" alignItems="center" onClick={onClickRedirect} sx={{ cursor: 'pointer' }}>
            <MenuItemFavorite dashboard={data} hideLabel noPadding />
            <Tooltip title={getTitle(data)} placement="top">
              <Typography
                noWrap
                maxWidth={180}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {getTitle(data)}
              </Typography>
            </Tooltip>
          </Box>
        )}
        subheader={(
          <Box paddingLeft={4} onClick={onClickRedirect} sx={{ cursor: 'pointer' }}>
            <Typography variant="subtitle2" color="gray">{formatRangeDate(data.events[0].operationTimeRange)}</Typography>
          </Box>
        )}
        titleTypographyProps={{ variant: 'h6' }}
        subheaderTypographyProps={{ variant: 'body2' }}
      />
      <Box
        onClick={onClickRedirect}
        className={styles.thumbnailBox}
        sx={{ backgroundImage: `url(${data.thumbnail})` }}
      >
        {!data.thumbnail
          && <Logo className="logoSvg" />}
      </Box>
      <CardContent className={styles.cardContent}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={0.7}
        >
          {!isDashboardShared && (
            <Box display="flex" gap={1}>
              <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                <LockPersonIcon />
                <Typography variant="caption" color="gray">Permissions</Typography>
              </Box>
              <PermissionChips isUserOwner={isUserOwner} role={userHighestRole} />
            </Box>
          )}

          {data.identities?.length > 0 && (
            <SharedWith data={data} userIdentity={loggedUserIdentity} />
          )}
          <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
            {isDashboardShared ? <GroupsIcon /> : isUserOwner
              ? <PersonIcon /> : <BusinessIcon />}
            <Typography
              variant="caption"
              color="gray"
            >
              Updated at {formatRangeDate(data.events?.[data.events?.length - 1].operationTimeRange)}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
