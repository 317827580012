import clsx from 'clsx';
import React from 'react';
import styles from './Locker.module.scss';

const LockerOverlay = ({ children, isLocked, lockedBy }) => {
  return (
    <div
      className={clsx({
        [styles.overlay]: true,
        [styles.overlayLocked]: isLocked,
      })}
    >
      {lockedBy ? `${lockedBy} is editing` : null}
    </div>
  );
};

export default LockerOverlay;
