import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { useScrollBlock, useUserDashboardPermissions } from '@/hooks';
import { USER_ROLES } from '@/constants/roles';
import getIsQlooUser from '@/utils/getIsQlooUser';
import MenuItemFavorite from './MenuItemFavorite';
import MenuItemShare from './MenuItemShare';
import MenuItemRename from './MenuItemRename';
import MenuItemClone from './MenuItemClone';
import MenuItemDelete from './MenuItemDelete';
import MenuItemOpenCreativesModal from './MenuItemOpenCreativesModal';

const MoreOptionsDashboard = ({
  dashboard, showFavorite, onConfirmDelete, onOpenCreativesModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();
  const { identity } = useSelector((state) => state.app);

  const isQlooUser = useMemo(() => getIsQlooUser(identity), [identity]);

  const {
    hasSharePermission,
    hasRenamePermission,
    hasClonePermission,
    hasDeletePermission,
  } = useUserDashboardPermissions(dashboard.id);

  const handleOpen = (event) => {
    event.stopPropagation();
    blockScroll();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    allowScroll();
    setAnchorEl(null);
  };

  const handleConfirmDelete = () => {
    if (onConfirmDelete) {
      onConfirmDelete();
    }
    handleClose();
  };

  // Enable scrolling when the component is unmounted
  useEffect(() => {
    return () => {
      allowScroll();
    };
  }, [allowScroll]);

  return (
    <div>
      <DashboardPermissionedControl
        dashboardId={dashboard.id}
        requiredPermissions={[USER_ROLES.EDITOR]}
      >
        <IconButton aria-label="settings" onClick={handleOpen}>
          <MoreVertIcon />
        </IconButton>
      </DashboardPermissionedControl>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {!!showFavorite && (
          <MenuItemFavorite dashboard={dashboard} />
        )}
        <MenuItemShare dashboard={dashboard} disabled={!hasSharePermission} />
        <MenuItemRename dashboard={dashboard} disabled={!hasRenamePermission} onClose={handleClose} />
        <MenuItemClone dashboard={dashboard} disabled={!hasClonePermission} />
        <MenuItemDelete dashboard={dashboard} disabled={!hasDeletePermission} onDeleted={handleConfirmDelete} />
        {isQlooUser ? <MenuItemOpenCreativesModal onClick={onOpenCreativesModal} /> : null}
      </Menu>
    </div>
  );
};

export default MoreOptionsDashboard;
