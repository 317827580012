import dayjs from 'dayjs';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { JSONTree } from 'react-json-tree';
import styles from './CreativesModal.module.scss';

const CreativesModalOutput = ({ output }) => {
  const { state } = output;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Show output
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.output}>
          <div className={styles.outputInner}>
            {state.sort((a, b) => a.timestamp - b.timestamp).map((item) => (
              <div className={styles.outputItem}>
                <div className={styles.outputItemText}>
                  {`[${item.index + 1}][${dayjs(item.timestamp).format('YYYY-MM-DD HH:mm:ss')}] ${item.text}`}
                </div>
                {item.collapsible ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {item.collapsible.label}
                    </AccordionSummary>
                    <AccordionDetails>
                      <JSONTree data={item.collapsible.json} invertTheme />
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CreativesModalOutput;
