import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { getDashboardCountAndFirstId } from '@/utils';
import { useGetDashboardsQuery, useCreateDashboardMutation } from '@/store/apis/dashboardsApi';

import Button from '@/components/Button';
import ErrorBoundary from '@/components/ErrorBoundary';
import DashboardTabs from './DashboardTabs/DashboardTabs';
import DashboardView from './DashboardView/DashboardView';
import DashboardPageErrorFallback from './DashboardPageErrorFallback';

// Others
import './index.scss';

const DashboardPage = () => {
  const navigate = useNavigate();
  const { isLoading } = useGetDashboardsQuery();
  const [createDashboard, { isLoading: isLoadingCreateDashboard }] = useCreateDashboardMutation();
  const { id } = useParams();
  const { items } = useSelector((state) => state.dashboards);
  const { dashboardIds } = useSelector((state) => state.dashboardTabs);

  const itemsData = Object.values(items) || [];

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  //   if (mediaQuery.matches) {
  //     setIsPredefinedDarkMode(true);
  //   }
  // }, []);

  useEffect(() => {
    const { firstId } = getDashboardCountAndFirstId(dashboardIds, items);
    if (!id && dashboardIds.length >= 1) {
      navigate(`/dashboards/view/${firstId}`);
    }
  }, [id, dashboardIds, items, navigate]);

  const handleCreate = async () => {
    const { data } = await createDashboard();
    navigate(`/dashboards/view/${data.id}`);
  };

  return (
    <ErrorBoundary FallbackComponent={DashboardPageErrorFallback}>
      <DashboardTabs isLoading={isLoading} />
      {id && <DashboardView isLoading={isLoading} key={id} />}
      {(!isLoading && !id) && (
        <Box display="flex" alignItems="center" justifyContent="center" height="100px">
          <Button
            isLoading={isLoadingCreateDashboard}
            onClick={handleCreate}
          >
            Add New Dashboard
          </Button>
        </Box>
      )}
    </ErrorBoundary>
  );
};

export default DashboardPage;
