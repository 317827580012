import { createTheme } from '@mui/material/styles';

const mainTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({
          noPadding, uppercase, bold,
        }) => ({
          textTransform: 'none',
          fontWeight: 400,
          ...(noPadding && {
            padding: '0 !important',
            minWidth: '34px',
          }),
          ...(uppercase && {
            textTransform: 'uppercase',
          }),
          ...(bold && {
            fontWeight: 600,
          }),
        }),
        sizeSmall: ({ noPadding }) => ({
          padding: noPadding ? 0 : '4px 8px',
          fontSize: '0.75rem',
        }),
      },
      variants: [
        {
          props: { variant: 'underlined' },
          style: ({ theme, ownerState }) => {
            const color = ownerState.color || 'primary';

            return {
              textDecoration: 'none',
              backgroundColor: 'transparent',
              color: theme.palette[color].contrastText,

              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            };
          },
        },
        {
          props: { variant: 'inline' },
          style: ({ theme, ownerState }) => {
            const color = ownerState.color || 'primary';
            const isDarkMode = theme.palette.mode === 'dark';

            return {
              textDecoration: 'none',
              display: 'inline',
              padding: '0 0.5rem',
              fontSize: '25px',
              fontWeight: 'bold',
              lineHeight: '1.2',
              borderRadius: 0,
              textAlign: 'left',
              backgroundColor: theme.palette[color].main,
              color: theme.palette[color].contrastText,

              '&:hover': {
                backgroundColor: isDarkMode
                  ? theme.palette[color].light
                  : theme.palette[color].dark,
              },

              '& > *': {
                display: 'inline',
                lineHeight: 1.6,
              },
            };
          },
        },
        {
          props: { variant: 'minimal' },
          style: {
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiTouchRipple-root': {
              display: 'none',
            },
          },
        },
        {
          props: { variant: 'map' },
          style: {
            width: '29px',
            height: '29px',
            minWidth: '29px',
            margin: '2px 2px 0 0',
            backgroundColor: '#fff',
            boxShadow: '0 0 0 2px rgba(0, 0, 0, .1)',
            borderRadius: '4px',

            '&:hover': {
              backgroundColor: '#f2f2f2',
              boxShadow: '0 0 0 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        {
          props: { variant: 'map', selected: true },
          style: ({ theme, ownerState }) => {
            const color = ownerState.color || 'primary';

            return {
              backgroundColor: theme.palette[color].main,
              color: theme.palette[color].contrastText,
              boxShadow: 'none',

              '&:hover': {
                backgroundColor: theme.palette[color].dark,
              },
            };
          },
        },
        {
          props: { shape: 'rounded' },
          style: {
            borderRadius: 50,
            minWidth: '32px',
          },
        },
        {
          props: { shape: 'square' },
          style: {
            borderRadius: 0,
          },
        },
        {
          props: { size: 'xs' },
          style: {
            padding: '2px 4px',
            fontSize: '0.65rem',
          },
        },
        {
          props: { font: 'Inter' },
          style: {
            fontFamily: 'Inter',
            letterSpacing: '1px',
          },
        },
        {
          props: { align: 'left' },
          style: {
            justifyContent: 'flex-start',
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ noPadding }) => ({
          ...(noPadding && {
            padding: 0,
            minWidth: 0,
          }),
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '1em',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputSizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

export default mainTheme;
