import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import QueryBuilder from '@/screens/Dashboard/QueryBuilder';
import styles from './CreativesModal.module.scss';
import CreativesModalMissingParams from './CreativesModalMissingParams';
import templatesByKey from './templates';
import getMissingRequiredParams from './getMissingRequiredParams';

const UI_SCHEMA_DEFAULT = {
  'ui:submitButtonOptions': {
    norender: true,
  },
};

const SelectWithPlaceholder = ({
  placeholder,
  value,
  onChange,
  children,
  sx,
  ...props
}) => (
  <Select
    displayEmpty
    value={value || ''}
    onChange={onChange}
    sx={sx}
    {...props}
  >
    <MenuItem value="" disabled>{placeholder}</MenuItem>
    {children}
  </Select>
);

const CreativesModalStepOne = ({
  baseParams,
  formRef,
  selectedTemplateKeyState,
  userDataState,
}) => {
  const [userData, setUserData] = userDataState;
  const [selectedTemplateKey, setSelectedTemplateKey] = selectedTemplateKeyState;

  const template = templatesByKey[selectedTemplateKey];

  const handleChange = ({ formData }) => {
    setUserData(formData);
  };

  // TODO this is a hack to make sure the form data is merged with the default form data
  useEffect(() => {
    if (selectedTemplateKey && template?.defaultFormData) {
      setUserData({
        ...userData,
        ...template.defaultFormData,
      });
    }
  }, [selectedTemplateKey]);

  const missingRequiredParams = useMemo(
    () => getMissingRequiredParams(baseParams, template?.required || []),
    [baseParams, selectedTemplateKey],
  );

  return (
    <div className={styles.contentContainer}>
      <Typography variant="h2" className={styles.baseParamsHeader}>
        {/* and this action */}
      </Typography>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <SelectWithPlaceholder
          placeholder="Select an action"
          onChange={(e) => setSelectedTemplateKey(e.target.value)}
          value={selectedTemplateKey}
          sx={{
            fontSize: '1.5rem',
          }}
        >
          {Object.entries(templatesByKey).map(([key, templateItem]) => (
            <MenuItem key={key} value={key}>
              <ListItemText
                primary={templateItem.name + (templateItem.credit ? ` by ${templateItem.credit}` : '')}
                secondary={templateItem.description || templateItem.credit}
              />
            </MenuItem>
          ))}
        </SelectWithPlaceholder>
      </FormControl>

      {!selectedTemplateKey || template?.hideBaseQuerySummary ? null : (
        <>
          {missingRequiredParams.length
            ? <CreativesModalMissingParams missingRequiredParams={missingRequiredParams} />
            : null}
          <div className={styles.baseParams}>
            <Typography variant="h2" className={styles.baseParamsHeader}>
              Using this audience
            </Typography>

            <QueryBuilder params={baseParams} onOpen={() => { }} veltLocation="">
              {({ DemographicsComponent, InterestsComponent, LocationComponent }) => (
                <div className={styles.baseParamsInner}>
                  <DemographicsComponent
                    color="orange"
                    className={clsx([styles.baseParamsPicker, styles.baseParamsPickerLightOrange])}
                  />
                  &nbsp;
                  <div>from</div>
                  &nbsp;
                  <LocationComponent
                    color="purple"
                    className={clsx([styles.baseParamsPicker, styles.baseParamsPickerLightPurple])}
                  />
                  &nbsp;
                  <div>that like{baseParams?.gender ? '' : 's'}</div>
                  &nbsp;
                  <InterestsComponent
                    color="blue"
                    className={clsx([styles.baseParamsPicker, styles.baseParamsPickerLightBlue])}
                  />
                </div>
              )}
            </QueryBuilder>

          </div>
        </>
      )}
      {template?.form ? (
        <div className={styles.formContainer}>
          <Form
            formData={userData}
            uiSchema={{
              ...UI_SCHEMA_DEFAULT,
              ...(template?.formUiSchema || {}),
            }}
            onChange={handleChange}
            ref={formRef}
            schema={template.form}
            validator={validator}
            disabled={!!missingRequiredParams.length}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CreativesModalStepOne;
