import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import SuperTokens from 'supertokens-auth-react';
import App from './App';
import './index.scss';

import SentryConfig from './sentry-config';
import { SuperTokensConfig } from './supertoken-frontend-config';

Sentry.init(SentryConfig);
SuperTokens.init(SuperTokensConfig);

// if (import.meta.hot) {
//     import.meta.hot.on('vite:beforeFullReload', () => {
//         throw '(skipping full reload)';
//     });
// }

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
