import clsx from 'clsx';
import { urnsToDisplayName } from '@qloo/categories';
import React from 'react';
import { ReactComponent as Logo } from '@v1/components/LogoSm.svg';
import { Typography, useTheme } from '@mui/material';
import { getColorForEntity, formatEntityCategories } from '@/utils';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import EntityImage from '@/components/EntityImage';
import OmnisearchResultsItem from './OmnisearchResultsItem';
import styles from './Omnisearch.module.scss';

const hierarchyFromUrn = (urn) => urn?.replace(/^urn:(tag:)?/, '').split(':');
const isUrn = (maybeUrn) => maybeUrn?.startsWith?.('urn:');
const slugToSentenceCaseName = (slug) => (
  slug?.split(/^[-_]+/).map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')
);

const bestGuessUrnName = (maybeUrn) => (
  !isUrn(maybeUrn)
    ? maybeUrn
    : hierarchyFromUrn(maybeUrn).map(slugToSentenceCaseName)
);

const EntitiesList = ({
  entities,
  entitiesStartIndex,
  entitiesGridClassName,
  getItemProps,
  resetPadding,
  showMini,
  valueBitmap,
}) => {
  const theme = useTheme();
  return (
    <div
      className={clsx({
        [styles.entities]: true,
        [styles.entitiesMini]: showMini,
        [styles.resetPadding]: resetPadding,
      })}
    >
      <div className={clsx([styles.entitiesGrid, entitiesGridClassName])}>
        {entities?.length > 0 ? (entities.map((entity, i) => {
          const hasImage = entity.properties?.images
          && entity.properties.images.length > 0
          && entity.properties.images[0]?.url
            ? entity.properties.images[0].url
            : entity.properties?.image?.url;

          return (
            <OmnisearchResultsItem
              className={clsx([
                styles.entitiesItem,
                styles.button,
              ])}
              isSelected={valueBitmap ? valueBitmap[entity.entity_id] : false}
              {...(getItemProps ? getItemProps({
                index: entitiesStartIndex + i,
                item: entity,
              }) : null)}
              toolTipContent={entity.disambiguation}
            >
              <div
                className={clsx([
                  styles.entitiesItemImageContainer,
                  { [styles.entitiesItemImageContainerMinified]: showMini },
                ])}
              >
                {hasImage ? (
                  <EntityImage
                    className={clsx([
                      styles.entitiesItemImage,
                    ])}
                    entity={entity}
                  />
                )
                  : (
                    <Logo
                      className={clsx([
                        'logoSvg',
                        { logoSvgMinified: showMini },
                      ])}
                    />
                  )}
              </div>
              <div
                className={clsx([
                  styles.entitiesItemInfo,
                ])}
              >
                <span
                  className={styles.entityCategoryText}
                  style={{
                    '--dynamic-category-color':
                          `var(--category-${formatCategoryUrnToCssVar(entity?.subtype || entity?.types[0])})`,
                  }}
                >
                  {urnsToDisplayName([entity?.subtype] || entity?.types)?.map(bestGuessUrnName)}
                </span>
                <div className={styles.entitiesItemName}>{entity.name}</div>
                <div className={styles.entitiesItemDisambiguation}>
                  {entity.disambiguation || ' '}
                </div>
                <div
                  className={styles.entitiesItemCategory}
                  style={{ color: `hsl(var(--${getColorForEntity(entity)}-tag-border))` }}
                >
                  {formatEntityCategories(entity)}
                </div>
              </div>
            </OmnisearchResultsItem>
          );
        })
        ) : (
          <div className={styles.placeholder}>
            <Typography
              component="pre"
              padding="10px"
              borderRadius={0.5}
              maxWidth={400}
              color={theme.palette.text.primary}
              sx={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: theme.palette.grey['50'],
              }}
            >
              No valid entities to display for this search.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default EntitiesList;
