import React from 'react';
import { Warning } from '@mui/icons-material';
import IconWithContent from '@/components/IconWithContent';
import Tooltip from '../index';
import styles from './TooltipWarning.module.scss';

const TooltipWarning = ({
  children, placement, message, conditionalRender,
}) => (
  <Tooltip
    placement={placement}
    title={(conditionalRender || conditionalRender === undefined) && (
      <IconWithContent
        iconComponent={Warning}
        iconSize={11}
        content={<span>{message}</span>}
      />
    )}
    classes={{ tooltip: styles.customTooltip, arrow: styles.customArrow }}
    arrow
  >{children}
  </Tooltip>
);

export default TooltipWarning;
