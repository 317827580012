import getIsFieldValueEmpty from '@/utils/getIsFieldValueEmpty';
import { serializeInsightsParamsObject } from '@/utils/serializeInsightsParams';

const getMissingRequiredParams = (baseParams, requiredParamNames = []) => {
  const serializedInsightsParamsObject = serializeInsightsParamsObject(baseParams);
  const result = requiredParamNames.filter((paramName) => getIsFieldValueEmpty(serializedInsightsParamsObject[paramName]));
  return result;
};

export default getMissingRequiredParams;
