import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Box } from '@mui/material';
import Popover from '@/components/Popover';
import CategoriesModal from '@/components/CategoriesModal';
import styles from '../PanelBodyExplore.module.scss';

const SelectedCategorySubcategoryDialog = ({
  selectedCategory,
  selectedSubCategories,
  handleSubCategoryChange,
  handleResetSubcategories,
  buttonLabel,
}) => {
  return (
    <Popover
      color="accent"
      variant="contained"
      size="small"
      renderContent={({ close }) => (
        <CategoriesModal
          category={selectedCategory}
          subCategories={selectedSubCategories}
          close={close}
          onReset={handleResetSubcategories}
          onCategoryChange={() => null}
          onSubcategoriesChange={handleSubCategoryChange}
          showSubCategorySelect
          hideCategorySelector
        />
      )}
      useDynamicPosition
      renderCustomTrigger={({ open }) => (
        <Box onClick={open} className={styles.modifierButtonLabel}>
          <span>{buttonLabel}</span>
          <FaChevronDown className={styles.arrowIcon} />
        </Box>
      )}
    />
  );
};

export default SelectedCategorySubcategoryDialog;
