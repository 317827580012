import React, { useCallback, useState } from 'react';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Button, Menu } from '@mui/material';
import { Plus } from 'react-feather';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { useActiveDashboard, useOwnership } from '@/hooks';
import { popovers } from '@/constants';
import { USER_ROLES } from '@/constants/roles';
import { useDashboardActionsContext } from '../../DashboardActionsProvider';
import QueryBuilder from '../../QueryBuilder';
import QueryBuilderPopover from '../../QueryBuilderPopover';
import ModifierItem from './ModifierItem';
import ModifierPanelItem from './ModifierPanelItem';

// import { popovers } from '../../helpers/constants';
import styles from './PanelParamsModifiers.module.scss';

const ModifierMenu = ({ popupState, onItemClick }) => {
  return (
    <>
      <Button
        variant="contained"
        color="accent"
        shape="square"
        font="Inter"
        size="small"
        disableElevation
        noPadding
        bold
        {...bindTrigger(popupState)}
      >
        <span className={styles.menuButtonLabel}>
          <Plus size={13} />
          <span>Modifier</span>
        </span>
      </Button>
      <Menu {...bindMenu(popupState)}>
        <ModifierPanelItem
          color="orange"
          label="Demographics"
          onClick={onItemClick(popovers.demographics)}
        />
        <ModifierPanelItem
          color="purple"
          label="Location"
          onClick={onItemClick(popovers.locations)}
        />
        <ModifierPanelItem
          color="blue"
          label="Interests"
          onClick={onItemClick(popovers.interests)}
        />
      </Menu>
    </>
  );
};

const PanelParamsModifiers = ({ baseParams, panelId, panelParams }) => {
  const dashboardActions = useDashboardActionsContext();
  const dashboard = useActiveDashboard();

  // Ownership
  const tag = `${panelId}-header`;
  const headerOwnership = useOwnership(tag);

  // Menu
  const menuPopupState = usePopupState({
    variant: 'popper',
    popupId: 'menuPopupState',
  });

  // Popover
  const [selectedPopover, setSelectedPopover] = useState(null);

  const queryBuilderPopupState = usePopupState({
    variant: 'popper',
    popupId: 'queryBuilderPopupState',
  });

  const handleChange = ({ key, value }) => {
    dashboardActions.changePanelParams({ panelId, nextParams: { [key]: value } });
  };

  const handleClose = () => {
    setSelectedPopover(null);
    menuPopupState.close();
    queryBuilderPopupState.close();
  };

  const handleOpen = useCallback((popover) => (e) => {
    setSelectedPopover(popover);
    queryBuilderPopupState.open(e);
  }, [queryBuilderPopupState]);

  const handleReset = (paramNames) => {
    dashboardActions.deletePanelParams({ panelId, paramNames });
  };

  return (
    <div className={styles.container}>
      <DashboardPermissionedControl
        dashboardId={dashboard.id}
        requiredPermissions={[USER_ROLES.EDITOR]}
      >
        <QueryBuilder onOpen={handleOpen} params={panelParams} veltLocation="">
          {() => <ModifierMenu popupState={menuPopupState} onItemClick={handleOpen} />}
        </QueryBuilder>
      </DashboardPermissionedControl>
      <DashboardPermissionedControl
        dashboardId={dashboard.id}
        requiredPermissions={[USER_ROLES.EDITOR]}
      >
        <QueryBuilder onOpen={handleOpen} params={panelParams} veltLocation="">
          {({
            hasDemographics,
            hasInterests,
            hasLocation,
            demographicsLabel,
            interestsLabel,
            locationLabel,
            openDemographicsPopover,
            openInterestsPopover,
            openLocationPopover,
          }) => (
            <div className={styles.baseQuery}>
              {!!hasDemographics && (
                <ModifierItem
                  color="orange"
                  label={demographicsLabel}
                  onClick={openDemographicsPopover}
                />
              )}
              {!!hasLocation && (
                <ModifierItem
                  color="purple"
                  label={locationLabel}
                  onClick={openLocationPopover}
                />
              )}
              {!!hasInterests && (
                <ModifierItem
                  color="blue"
                  label={interestsLabel}
                  onClick={openInterestsPopover}
                />
              )}
            </div>
          )}
        </QueryBuilder>
      </DashboardPermissionedControl>
      <QueryBuilderPopover
        baseParams={baseParams}
        lock={headerOwnership.set}
        popupState={queryBuilderPopupState}
        onChange={handleChange}
        onClose={handleClose}
        onReset={handleReset}
        selectedPopover={selectedPopover}
        params={panelParams}
        releaseLock={headerOwnership.clear}
      />
    </div>
  );
};

export default PanelParamsModifiers;
