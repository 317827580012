import React from 'react';
import Identity from '@/components/Identity';
import { AvatarGroup } from '@mui/material';
import DashboardUserChipAvatar from './DashboardUserChipAvatar';

const DashboardUserChipAvatarGroup = ({
  data, max = 5, sx, fontSizeLabel,
}) => {
  const isShowLabel = data?.length === 1;
  return (
    <AvatarGroup
      max={max}
      sx={{
        ...sx,
        '& .MuiAvatar-root': { width: 36, height: 36 },
      }}
    >
      {data?.length ? data.map((el) => (
        <Identity identityId={el.identityId}>
          {({ identity }) => (
            <DashboardUserChipAvatar
              key={identity.identityId}
              label={identity.firstName || identity.fullName}
              email={identity.loginEmailAddress}
              userId={identity.identityId}
              fontSizeLabel={fontSizeLabel}
              isStyleGroupAvatar
              isShowLabel={isShowLabel}
            />
          )}
        </Identity>
      )) : (
        <span> - </span>
      )}
    </AvatarGroup>
  );
};

export default DashboardUserChipAvatarGroup;
