import React, { useEffect, useRef, useState } from 'react';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import MuiPopover from '@mui/material/Popover';
import { styled } from '@mui/system';
import { useScrollBlock } from '@/hooks';
import Button from '../Button';

const positions = {
  bottom: 'bottom',
  right: 'right',
};

// Styled Button to prevent unwanted props
const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'popupState', // Exclude popupState
})(() => ({
  // Your styles here
}));

const getAnchorPosition = ({
  container, event, position, useDynamicPosition,
}) => {
  if (useDynamicPosition) {
    return {
      top: event.clientY,
      left: event.clientX,
    };
  }

  if (container) {
    const {
      bottom, top, left, width,
    } = container.getBoundingClientRect();
    const { height } = event.target.getBoundingClientRect();

    if (position === positions.right) {
      return {
        top,
        left: left + width,
      };
    }

    if (position === positions.bottom) {
      return {
        top: top + height,
        left,
      };
    }

    return {
      top: bottom,
      left: left + width / 2 - 100,
    };
  }

  return {
    top: 0,
    left: 100,
  };
};

const Popover = ({
  className,
  matchTriggerWidth,
  PaperProps,
  position,
  renderCustomTrigger,
  renderContent,
  renderTrigger,
  shouldTriggerWithHover,
  transformOrigin,
  useDynamicPosition,
  ...props
}) => {
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [blockScroll, allowScroll] = useScrollBlock();
  const containerRef = useRef(null);

  const basePopupState = usePopupState({
    variant: 'popper',
    popupId: 'demoPopper',
  });

  const popupState = props.popupState || basePopupState;

  const handleClose = () => {
    popupState.close();
  };

  const handleOpen = (event) => {
    blockScroll();
    const nextAnchorPosition = getAnchorPosition({
      container: containerRef.current,
      event,
      position,
      useDynamicPosition,
    });
    setAnchorPosition(nextAnchorPosition);
    popupState.open();
  };

  const handleMouseEnter = (e) => {
    if (shouldTriggerWithHover) {
      handleOpen(e);
    }
  };

  const popoverProps = bindPopover(popupState);
  const { popupState: _, ...popoverDOMProps } = popoverProps;

  useEffect(() => {
    if (!popupState.isOpen) {
      allowScroll();
    }

    return () => {
      allowScroll();
    };
  }, [popupState.isOpen, allowScroll]);

  return (
    <div ref={containerRef}>
      {renderCustomTrigger ? (
        renderCustomTrigger({
          open: handleOpen,
          isOpen: popupState.isOpen,
        })
      ) : (
        <StyledButton
          onClick={handleOpen}
          onMouseEnter={handleMouseEnter}
          className={className}
          {...props} // Pass other props, excluding popupState
        >
          {renderTrigger({ isOpen: popupState.isOpen })}
        </StyledButton>
      )}
      <MuiPopover
        {...popoverDOMProps}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition || undefined}
        sx={matchTriggerWidth && {
          '& .MuiPopover-paper': {
            // Setting the width of the popover to match the trigger
            width: popupState.anchorEl ? popupState.anchorEl.offsetWidth : 'auto',
            backgroundColor: 'hsl(var(--background))',
          },
        }}
        onClose={handleClose}
        open={popupState.isOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {popupState.isOpen ? renderContent({ close: handleClose }) : null}
      </MuiPopover>
    </div>
  );
};

export default Popover;
