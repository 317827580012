import form from './formSchema.json';
import formUiSchema from './formUiSchema.json';
import runner from './runner';
import render from './Render';

const Spokesperson = {
  name: '👤 Spokesperson selection',
  description: 'Select a spokesperson for your campaign or media',
  form,
  formUiSchema,
  defaultFormData: {
    Categories: [{ name: 'People' }],
    instructions: 'commercial for alcoholic beverage',
  },
  insights: {
    entities: [
      { category: 'person', params: { take: 20 } },
    ],
    tags: [
      // {
      //   // Doesn't work
      //   category: 'brand', params: {
      //     take: 10,
      //     'filter.parents.types': 'urn:entity:brand',
      //     'filter.tag.types': 'urn:tag:aesthetic_property:qloo',
      //   },
      // },
    ],
  },
  render,
  runner,
};

export default Spokesperson;
