import React, { useMemo } from 'react';
import { findPriority, useCategoryPerms } from '@v1/lib/usePermissions';
import clx from 'clsx';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { paramNames } from '@/constants';
import { useGetInsightQuery } from '@/store/apis/insightsApi';
import useGPTExplainRecs from '@/hooks/useGPTExplainRecs';
import Overlay from '@/components/Overlay';
import { serializeInsightsParams } from '@/utils';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import CategoryData from './CategoryData/CategoryData';
import ResumedCategoryData from './ResumedCategory/ResumedCategoryData';
import { HIGHLIGHT_DATA, tagTypesBySelectedCategory } from './constants';
import useTagsFetcher from './useTagsFetcher';
import validateQueryError from '../helpers/validateQueryError';
import PanelBodyError from '../PanelBodyError';
import CategoryHighlightedData from './CategoryData/CategoryHighLightedData';
import ResumedCategoryHighlightedData from './ResumedCategory/ResumedCategoryHighlightedData';
import { getTagsForCategory } from '../helpers';
import styles from './PanelBodyExplore.module.scss';
import CategoryDataSkeleton from './CategoryData/CategoryDataSkeleton';

export const CategoryDataView = ({
  category,
  onSelect,
  baseParams,
  conciseView,
  isEmptyBaseQuery,
  panel,
}) => {
  const serializedTagsInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: 'urn:tag',
      [paramNames.filterParentTypes]: category,
      take: 4,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [category, baseParams]);

  const {
    data: tagsResult,
    isLoading: isLoadingTags,
    error: tagsError,
  } = useGetInsightQuery(serializedTagsInsightsParams);

  const serializedInsightsParams = useMemo(() => {
    const tempParams = {
      [paramNames.filterType]: category,
      [paramNames.take]: 3,
    };

    return serializeInsightsParams(baseParams, tempParams);
  }, [baseParams, category]);

  const {
    data: entitiesResult,
    isLoading: isLoadingEntities,
    error: insightsError,
  } = useGetInsightQuery(serializedInsightsParams);

  const tagsFetcher = useTagsFetcher();
  const tagTypes = tagTypesBySelectedCategory[category] || [];
  const tags = tagsResult?.results?.tags || [];
  const entities = entitiesResult?.results?.entities || [];
  const {
    result: gptResult,
    isLoading: isLoadingGPT,
    error: gptError,
  } = useGPTExplainRecs(
    {
      category, tags, entities, showMini: conciseView,
    },
    {
      skip: isLoadingEntities
            || isLoadingTags,
    },
  );

  const errorData = useMemo(() => {
    const errors = [insightsError, tagsError, gptError];
    return validateQueryError({ errors });
  }, [insightsError, tagsError, gptError]);

  useDeepCompareEffect(() => {
    if (tagTypes.length) {
      tagsFetcher.fetchByTagTypes({
        baseParams: { ...baseParams, ...panel.params },
        pagination: {
          [paramNames.offset]: 0,
          [paramNames.take]: 9,
        },
        selectedCategory: category,
        tagTypes,
      });
    }
  }, [baseParams, category, panel.params]);

  if (isLoadingEntities || isLoadingGPT || isLoadingTags) {
    return <CategoryDataSkeleton conciseView={conciseView} />;
  }

  const categoryColor = `var(--category-${formatCategoryUrnToCssVar(category)})`;

  return (
    <>
      {HIGHLIGHT_DATA.some((data) => data.category === category)
          && getTagsForCategory(category).map((tag, index) => {
            return !conciseView ? (
              <CategoryHighlightedData
                baseParams={baseParams}
                category={category}
                onSelect={onSelect}
                tag={tag}
                conciseView={conciseView}
                key={index}
                tagTypes={tagTypes}
                tagsFetcher={tagsFetcher}
              />
            ) : (
              <ResumedCategoryHighlightedData
                baseParams={baseParams}
                category={category}
                onSelect={onSelect}
                tag={tag}
                conciseView={conciseView}
                key={index}
                categoryColor={categoryColor}
                tagTypes={tagTypes}
                tagsFetcher={tagsFetcher}
              />
            );
          })}

      {!conciseView && (
        <CategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          gptResult={gptResult}
          error={errorData}
          isEmptyBaseQuery={isEmptyBaseQuery}
          tagTypes={tagTypes}
          tagsFetcher={tagsFetcher}
        />
      )}
      {conciseView && (
        <ResumedCategoryData
          category={category}
          onSelect={onSelect}
          tagsResult={tagsResult}
          entitiesResult={entitiesResult}
          categoryColor={categoryColor}
          error={errorData}
          gptResult={gptResult}
          tagTypes={tagTypes}
          tagsFetcher={tagsFetcher}
        />
      )}
    </>
  );
};

const CategoriesDataList = ({
  onSelect,
  baseParams,
  isExploreLocation,
  visible,
  panel,
}) => {
  const categories = Object.keys(useCategoryPerms()).sort((a, b) => {
    const aPriority = findPriority(a);
    const bPriority = findPriority(b);
    if (aPriority !== bPriority) {
      return aPriority - bPriority;
    }
    return a.localeCompare(b);
  });
  const { isExploreConciseView } = useSelector((state) => state.dashboards);
  const isEmptyBaseQuery = useMemo(
    () => Object.keys(baseParams).length === 0
      || Object.values(baseParams).every(
        (value) => (Array.isArray(value) && value.length === 0) || !value,
      ),
    [baseParams],
  );

  return (
    <div
      className={clx(
        styles.mainContainer,
        { [styles.hidden]: !visible },
      )}
    >
      <div className={clx(
        styles.categoriesDataListGrid,
        { [styles.categoriesDataListGridConcise]: isExploreConciseView },
      )}
      >
        {categories.map((category) => {
          return (
            <CategoryDataView
              baseParams={baseParams}
              category={category}
              key={category}
              panel={panel}
              onSelect={onSelect}
              conciseView={isExploreLocation ? true : isExploreConciseView}
              isEmptyBaseQuery={isEmptyBaseQuery}
            />
          );
        })}
      </div>
      {isEmptyBaseQuery && (
        <Overlay>
          <PanelBodyError message="At least one base parameter is required to show the entities response." />
        </Overlay>
      )}
    </div>
  );
};

export default CategoriesDataList;
