import React from 'react';
import { getReasonPhrase } from 'http-status-codes';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ErrorFallback from '@/components/ErrorFallback';
import extractErrorMessage from './utils/extractErrors';

import styles from './App.module.scss';

const ErrorCard = ({ error }) => {
  const title = error.status ? `${error.status} - ${getReasonPhrase(error.status)}` : 'Error';

  return (
    <Card
      className={styles.card}
      title={title}
      description="Oops! Something went wrong"
      actions={[
        <Button variant="link" noPadding href="mailto:saas@qloo.com?subject=query help&body=Hi!, I need help with...">
          Contact Support
        </Button>,
        <Button key="reload" onClick={() => window.location.reload()}>Reload</Button>,
      ]}
    >
      <div className={styles.message}>
        <pre>{extractErrorMessage(error)}</pre>
      </div>
    </Card>
  );
};

const AppErrorFallback = ({ error }) => {
  return (
    <ErrorFallback
      backgroundRenderer={() => null}
      errorRenderer={() => <ErrorCard error={error} />}
    />
  );
};

export default AppErrorFallback;
