import clsx from 'clsx';
import React from 'react';
import formatCategoryUrnToCssVar from '@/utils/formatCategoryUrnToCssVar';
import formatTagType from '@/screens/Dashboard/DashboardPanel/PanelBodyExplore/formatTagType';
import CategorizedTagsItems from './CategorizedTagsItems';
import styles from './CategorizedTags.module.scss';

const CategorizedTags = ({ category, tags, limit }) => {
  return (
    <div
      className={clsx({
        [styles.categoryDataTagsItem]: true,
      })}
      style={{
        '--dynamic-category-color':
                `var(--category-${formatCategoryUrnToCssVar(category)})`,
      }}
    >
      <span className={styles.categoryDataTagsItemLabel}>{formatTagType(category)}</span>
      <CategorizedTagsItems tags={tags} limit={limit} />
    </div>
  );
};

export default CategorizedTags;
