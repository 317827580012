import React from 'react';
import { bindPopover } from 'material-ui-popup-state/hooks';
import Popover from '@mui/material/Popover';
import Locker from '@/components/Locker';
import { paramNames } from '@/constants/paramNames';
import { popovers } from '@/constants';
import QueryBuilderPopoverPanel from './QueryBuilderPopoverPanel';
import { demographicsParamNames } from '../constants';

const getPopoverProps = ({ onClose, selectedPopover }) => {
  switch (selectedPopover) {
    case popovers.demographics:
      return {
        color: 'orange',
        paramNames: demographicsParamNames,
        title: 'Demographics',
      };
    case popovers.interests:
      return {
        color: 'teal',
        onUpdate: (prevValue, nextValue) => {
          if ((nextValue?.length || 0) > (prevValue?.length || 0)) {
            onClose();
          }
        },
        paramNames: [paramNames.omnisearch],
        title: 'Interests',
      };
    case popovers.locations:
      return {
        // onUpdate: onClose,
        color: 'purple',
        paramNames: [paramNames.signalLocation],
        title: 'Location',
      };
    default:
      return {
        paramNames: [],
        title: '',
      };
  }
};

const QueryBuilderPopover = ({
  baseParams = {},
  lock,
  onChange,
  onClose,
  onReset,
  selectedPopover,
  params,
  popupState,
  releaseLock,
}) => {
  const isLocationsPopover = selectedPopover === popovers.locations;

  return (
    <Popover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorPosition={
        popupState.anchorPosition
      }
      anchorReference="anchorPosition"
      onClose={onClose}
      PaperProps={
        isLocationsPopover
          ? { style: { width: '800px' } }
          : { style: { minWidth: '550px' } }
      }
      transformOrigin={{
        vertical: 'top',
        horizontal: isLocationsPopover ? 'center' : 'left',
      }}
    >
      <Locker lock={lock} releaseLock={releaseLock}>
        <QueryBuilderPopoverPanel
          baseParams={baseParams}
          close={onClose}
          onChange={onChange}
          onReset={onReset}
          params={params}
          selectedPopover={selectedPopover}
          {...getPopoverProps({ onClose, selectedPopover })}
        />
      </Locker>
    </Popover>
  );
};

export default QueryBuilderPopover;
