/* eslint-disable import/no-cycle */
// TODO: Fix circular dependency
import React from 'react';
import { fieldTypes } from '@/constants';
import Audiences from '../Audiences';
import CheckboxList from '../CheckboxList';
import Input from '../Input';
import LocationPicker from '../LocationPicker';
import Omnisearch from '../Omnisearch';
import PillPicker from '../PillPicker';
import PillPickerMultiple from '../PillPicker/PillPickerMultiple';
import RadioGroup from '../RadioGroup';
import RangeInputs from '../RangeInputs';
import Rating from '../Rating';
import RatingRange from '../RatingRange';
import Slider from '../Slider';

const FieldRenderer = ({
  disabled,
  field,
  onChange,
  onReset,
  value,
}) => {
  switch (field.type) {
    case fieldTypes.checkboxList:
      return <CheckboxList disabled={disabled} options={field.options} onChange={onChange} value={value} />;

    case fieldTypes.location:
      return <LocationPicker onChange={onChange} value={value} onReset={onReset} color="purple" />;

    case fieldTypes.omnisearch:
      return <Omnisearch onChange={onChange} value={value} />;

    case fieldTypes.pillPicker:
      return (
        <PillPicker options={field.options} onChange={onChange} value={value} />
      );

    case fieldTypes.pillPickerMultiple:
      return (
        <PillPickerMultiple options={field.options} onChange={onChange} value={value} />
      );

    case fieldTypes.rangeInputs:
      return (
        <RangeInputs min={field.min} max={field.max} onChange={onChange} value={value} />
      );

    case fieldTypes.radio:
      return (
        <RadioGroup onChange={onChange} options={field.options} value={value} />
      );

    case fieldTypes.rating:
      return (
        <Rating
          onChange={onChange}
          value={value}
          defaultValue={field.defaultValue}
        />
      );

    case fieldTypes.ratingRange:
      return (
        <RatingRange
          defaultValue={field.defaultValue}
          onChange={onChange}
          value={value}
          useMoneyIcon={field?.useMoneyIcon}
          max={field?.max ?? undefined}
        />
      );

    case fieldTypes.slider:
      return (
        <Slider
          onChange={onChange}
          value={value}
          {...field}
        />
      );

    case fieldTypes.text:
      return <Input type="text" onChange={onChange} value={value} />;

    case fieldTypes.audiences:
      return (
        <Audiences onChange={onChange} value={value} />
      );
    default:
      return null;
  }
};

export default FieldRenderer;
