import { Popover } from '@mui/material';
import { X } from 'react-feather';

import FilterWrapper from './FilterWrapper';
import Button from './Button';
import LocationPicker from './LocationPicker';

const LocationPopover = ({
  location, anchorEl, onClose, onChange, onReset,
}) => (
  <Popover
    id="location-popover"
    anchorEl={anchorEl}
    onClose={onClose}
    open={!!anchorEl}
    PaperProps={{
      style: {
        width: '100%',
        maxWidth: 800,
        backgroundColor: 'hsl(var(--background))',
        color: 'hsl(var(--foreground))',
      },
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
    <FilterWrapper
      title="Location"
      variant="primary"
      renderActions={() => [
        <Button variant="text" color="accent" onClick={onClose}><X size={16} /></Button>,
      ]}
    >
      <LocationPicker
        value={location}
        color="blue"
        onChange={onChange}
        onReset={onReset}
      />
    </FilterWrapper>
  </Popover>
);

export default LocationPopover;
