import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { Box } from '@mui/system';

import { filterTypes } from '@/constants';
import DashboardPermissionedControl from '@/components/DashboardPermissionedControl';
import { USER_ROLES } from '@/constants/roles';
import { useActiveDashboard } from '@/hooks';
import { useDashboardActionsContext } from '../DashboardActionsProvider';
import { rowSizeOptions } from './helpers/constants';

import styles from './DashboardPanel.module.scss';

const TablePageSizeSelector = ({
  panelId, panelParams, filterType, explorePagination, updateExplorePagination,
}) => {
  const dashboardActions = useDashboardActionsContext();
  const isExploreFilterType = [filterTypes.explore, filterTypes.locationExplorer].includes(filterType);
  const dashboard = useActiveDashboard();

  const handlePageSizeChange = (e) => {
    const nextParams = {
      offset: 0,
      take: e.target.value,
    };

    if (isExploreFilterType) {
      updateExplorePagination(nextParams);
      return;
    }

    dashboardActions.changePanelParams({ panelId, nextParams });
  };

  return (
    <DashboardPermissionedControl
      dashboardId={dashboard.id}
      requiredPermissions={[USER_ROLES.EDITOR]}
    >
      <Box display="flex" gap="8px">
        <span className={styles.pageSizeLabel}>Rows per page:</span>
        <Select
          labelId="page-size"
          id="page-size"
          value={isExploreFilterType ? explorePagination.take : panelParams.take}
          label="Page Size"
          onChange={handlePageSizeChange}
          size="small"
          variant="standard"
        >
          {rowSizeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </DashboardPermissionedControl>
  );
};

export default TablePageSizeSelector;
