import { filterTypes, paramNames } from '@/constants';

/**
 * Transforms heatmap data into an array of items with location details.
 *
 * @param {Array} data - The raw heatmap data.
 * @returns {Array<{lat: number, lon: number, geohash: string, query: string}>} An array of items with location details. */
export const getHeatmapItems = (data) => data.map((item) => ({
  lat: item.location.latitude,
  lon: item.location.longitude,
  geohash: item.location.geohash,
  query: item.query,
}));

/**
 * Retrieves data based on the specified filter type.
 *
 * @param {string} filterType - The type of filter to apply. Can be one of the values defined in `filterTypes`.
 * @param {Object} panelData - The data from which to retrieve filtered results.
 * @returns {Array<{entity:object, rank:number}>} The filtered entities + rank based on the filter type.
 */
const getDataByFilterType = ({ filterType, panelData, params }) => {
  if (panelData?.error || panelData?.errors) {
    return {
      error: panelData.error,
      errors: panelData.errors,
    };
  }

  if (filterType === filterTypes.tag) {
    return panelData?.results?.tags || [];
  }

  if (filterType === filterTypes.heatmap) {
    return getHeatmapItems(panelData?.results?.heatmap || []);
  }

  const offset = params[paramNames.offset] || 0;
  return panelData?.results?.entities.map((entity, i) => ({ ...entity, rank: offset + i + 1 })) || [];
};

export default getDataByFilterType;
